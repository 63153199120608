import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { FormikProps, Form } from 'formik';
import { Button } from 'modules/theme/components/button/Button';
import { Input } from 'modules/theme/components/fields/input/Input';
import { CheckboxWithLabel } from 'modules/theme/components/fields/checkbox/CheckboxWithLabel';
import {IContactAdvertiser} from 'components/page/advertisement/detail/interfaces/advertiser/IContactAdvertiser';
import {
    InputsMergedWrapper
} from 'modules/theme/components/fields/input/partials/InputsMergedWrapper';
import {CheckboxDisclaimer} from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/common/checkboxDisclaimer/CheckboxDisclaimer';
import {Text} from 'modules/theme/components/text/Text';

interface IContactForm extends FormikProps<IContactAdvertiser> {
    disabled: boolean;
}

export const SimplifiedContactForm: FC<IContactForm> = ({
    errors,
    values,
    touched,
    disabled,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    isValid,
}) => {
    return (
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container direction="column" rowGap={2}>
                <Grid item>
                    <Text variant={'h4'} semibold>
                        Ako sa vám máme ozvať naspäť
                    </Text>
                </Grid>

                <Grid item>
                    <InputsMergedWrapper>
                        <Input
                            disabled={disabled}
                            value={values.name}
                            label="Meno a priezvisko *"
                            errorMsg={touched.name && errors.name}
                            onChange={(value) => setFieldValue('name', value)}
                            onBlur={() => setFieldTouched('name')}
                        />
                        <Input
                            label="Telefón *"
                            disabled={disabled}
                            value={values.phoneNumber}
                            errorMsg={touched.phoneNumber && errors.phoneNumber}
                            onChange={(value) =>
                                setFieldValue('phoneNumber', value)
                            }
                            onBlur={() => setFieldTouched('phoneNumber')}
                        />
                        <Input
                            label="E-mail *"
                            disabled={disabled}
                            value={values.mail}
                            errorMsg={touched.mail && errors.mail}
                            onChange={(value) => setFieldValue('mail', value)}
                            onBlur={() => setFieldTouched('mail')}
                        />
                    </InputsMergedWrapper>
                </Grid>
                <Grid item>
                    <CheckboxWithLabel
                        disabled={disabled}
                        isChecked={values.gdpr}
                        label={<CheckboxDisclaimer />}
                        errMsg={touched.gdpr && errors.gdpr}
                        onClick={(value) => setFieldValue('gdpr', value)}
                        onBlur={() => setFieldTouched('gdpr')}
                    />
                </Grid>

                {/*<Grid item>*/}
                {/*    <Recaptcha*/}
                {/*        errorMsg={*/}
                {/*            touched.recaptchaToken && errors.recaptchaToken*/}
                {/*        }*/}
                {/*        onChange={(token) =>*/}
                {/*            setFieldValue('recaptchaToken', token)*/}
                {/*        }*/}
                {/*    />*/}
                {/*</Grid>*/}

                <Grid item>
                    <Button
                        type="submit"
                        disabled={disabled || !isValid}
                        text="Odoslať e-mail"
                    />
                </Grid>
            </Grid>
        </Form>
    );
};
