import { FC } from 'react';
import { Box, Theme, Divider } from '@mui/material';
import { IAdvertiser } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/advertiser/IAdvertiser';
import { ButtonsBox } from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStickyOld/partials/buttonsBox/ButtonsBox';
import { IAdvertisementGtmItem } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import { EPosition } from 'modules/gtmEvents/enums/advertisement/detail/EPosition';
import { SimplifiedAdvertiserProfile } from 'components/page/advertisement/detail/components/common/advertiser/common/advertiserProfile/SimplifiedAdvertiserProfile';

interface IAdvertiserBox {
    advertiser: IAdvertiser;
    advertisementId: string;
    advertisementGtmItem: IAdvertisementGtmItem;
    position: EPosition;
    isPhotoGallery?: boolean;
    isMobile: boolean;
    isPlentyOfPhotos?: boolean;
}

export const AdvertiserBoxOld: FC<IAdvertiserBox> = ({
    advertiser,
    advertisementId,
    advertisementGtmItem,
    isPhotoGallery,
    position,
    isMobile,
    isPlentyOfPhotos
}) => {
    const agencyOrBranch = advertiser.branch
        ? advertiser.branch
        : advertiser.agency;

    return (
        <Box
            pb={3}
            pl={3}
            pr={3}
            borderRadius={4}
            pt={isPlentyOfPhotos ? 4 : 5}
            border={'1px solid rgba(182, 182, 182, 0.36)'}
            sx={{
                background: (theme: Theme) =>
                    theme.palette.backgroundPrimary.main,
            }}
            boxShadow={'0px 8px 24px 0px rgba(34, 34, 34, 0.08)'}
        >
            <Box mb={2}>
                <ButtonsBox
                    advertiserId={advertiser.id}
                    advertisementId={advertisementId}
                    advertisementGtmItem={advertisementGtmItem}
                    isPhotoGallery={isPhotoGallery}
                    position={position}
                    isMobile={isMobile}
                    phoneNumber={advertiser.mainPhone}
                />
            </Box>

            <Box mb={2}>
                <Divider/>
            </Box>

            <SimplifiedAdvertiserProfile
                id={advertiser.id}
                name={advertiser.name}
                type={advertiser.type}
                isPro={advertiser.isPro}
                slugName={advertiser.slugName}
                photoUrl={advertiser.photoUrl}
                agencyName={agencyOrBranch?.name}
                advertisementId={advertisementId}
            />
        </Box>
    );
};
