import { FC } from 'react';
import { Grid } from '@mui/material';
import { IAttribute } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/parameters/partials/attribute/IAttribute';
import { Parameter } from 'modules/theme/components/listing/parameter/components/Parameter';
import BulletIcon from 'modules/theme/components/icons/basic/Bullet.svg';

interface IProperties {
    attributes: IAttribute[];
}

export const Properties: FC<IProperties> = ({ attributes }) => {
    return (
        <Grid container spacing={1}>
            {attributes.map((attribute) => (
                <Grid key={attribute.value} item xs={12} md={6}>
                    <Parameter
                        key={attribute.value}
                        icon={BulletIcon}
                        label={attribute.label}
                        value={attribute.value}
                        isHighlighted={attribute.isImportant}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
