import React, {FC, useCallback, useMemo, useState} from 'react';
import {Box, Divider, Grid, useTheme} from '@mui/material';
import {
    getAddToCartEvent,
    getClickPhoneNumberEvent,
    getGenerateLeadEvent, getPurchaseEvent,
} from 'modules/gtmEvents/helpers/events/advertisement/common/advertisementEventsHelper';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import {getResetEcommerceEvent} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import {EFormState} from 'modules/gtmEvents/enums/EFormState';
import {EFormStep} from 'modules/gtmEvents/enums/EFormStep';
import {EMethod} from 'modules/gtmEvents/enums/EMethod';
import useSession from 'modules/state/app/hook/session/useSession';
import {EPosition} from 'modules/gtmEvents/enums/advertisement/detail/EPosition';
import {Text} from 'modules/theme/components/text/Text';
import {
    SwitchButtons
} from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStickyOld/partials/buttonsBox/partials/switchButtons/SwitchButtons';
import {
    Phone
} from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStickyOld/partials/phone/Phone';
import {
    AdvertiserContactTypeEnum
} from 'components/page/advertisement/detail/enums/advertiser/AdvertiserContactTypeEnum';
import {
    ContactButton
} from 'components/page/advertisement/detail/components/common/advertiser/partials/common/contactButton/ContactButton';
import {TextArea} from 'modules/theme/components/fields/textArea/TextArea';
import {useRecoilState, useResetRecoilState} from 'recoil';
import {advertiserFormTextState} from 'modules/state/page/advertisement/state';
import { useTracker as useRtbTracker } from 'modules/analytics/rtb/hooks/util/visit/useTracker';

interface IButtonsBox {
    advertiserId: string;
    advertisementId: string;
    advertisementGtmItem: IAdvertisementGtmItem;
    position: EPosition;
    isPhotoGallery?: boolean;
    isMobile: boolean;
    phoneNumber?: string;
}

export const ButtonsBox: FC<IButtonsBox> = ({
    advertiserId,
    advertisementId,
    advertisementGtmItem,
    isPhotoGallery,
    position,
    isMobile,
    phoneNumber,
}) => {
    const [contactType, setContactType]
        = useState<AdvertiserContactTypeEnum>(AdvertiserContactTypeEnum.MORE_INFO);
    const [formText, setFormText]
        = useRecoilState<string>(advertiserFormTextState);
    const resetFormText = useResetRecoilState(advertiserFormTextState);

    const formTextError = formText.length === 0;

    const theme = useTheme();
    const { session } = useSession();
    const isAdvertiserLoggedIn = !!session;

    const {
        facebookPixel: { sendEvent: sendFacebookPixelEvent },
        gtm: { sendEvent: gtmSendEvent },
        livemonitor: { sendEvent: livemonitorSendEvent },
    } = useAnalytics();

    const {
        advertiser,
        devProjectId,
    } = advertisementGtmItem;

    const phoneNumberLmEventNskParams = useMemo(() => {
        return ['trackEvent', (isPhotoGallery ? 'Advert-fotogallery' : 'Advert'), 'Contact', 'Telefon'];
    }, [isPhotoGallery]);

    const phoneNumberLmEventPltParams = useMemo(() => {
        return ['trackEvent', 'advert-contact-nehnutelnostisk', advertisementId, 'Telefon'];
    }, [advertisementId]);

    const emailButtonLmEventNskParams = useMemo(() => {
        if (contactType === AdvertiserContactTypeEnum.MORE_INFO) {
            return ['trackEvent', (isPhotoGallery ? 'Advert-fotogallery' : 'Advert'), 'Contact', 'Mail'];
        }
        if (contactType === AdvertiserContactTypeEnum.INSPECTION) {
            return ['trackEvent', (isPhotoGallery ? 'Advert-fotogallery' : 'Advert'), 'Contact-obhliadka', 'Mail'];
        }
        return [];
    }, [contactType, isPhotoGallery]);

    const emailButtonLmEventPltParams = useMemo(() => {
        if (contactType === AdvertiserContactTypeEnum.MORE_INFO) {
            return ['trackEvent', 'advert-contact-nehnutelnostisk', advertisementId, 'Mail'];
        }
        if (contactType === AdvertiserContactTypeEnum.INSPECTION) {
            return ['trackEvent', 'advert-contact-obhliadka-nehnutelnostisk', advertisementId, 'Mail'];
        }
        return [];
    }, [advertisementId, contactType]);

    const {
        rtbEventMobile,
    } = useRtbTracker();

    const onShowPhoneNumber = useCallback(() => {
        livemonitorSendEvent({
            p: phoneNumberLmEventNskParams, o:{pn: 'nehnutelnosti'}
        });
        livemonitorSendEvent({
            p: phoneNumberLmEventPltParams, o:{pn: 'plt-stats'}
        });
        sendFacebookPixelEvent(['track', 'AddToCart']);
        gtmSendEvent(
            getGenerateLeadEvent(
                advertisementGtmItem.id,
                advertiser.id,
                advertiser.name,
                EFormState.SUCCESS,
                EFormStep.STEP_1,
                EMethod.SRL_SHOW_PHONE_NUMBER,
                position,
                advertiser.agency?.name,
                devProjectId,
                isAdvertiserLoggedIn,
                isPhotoGallery,
            ),
            getResetEcommerceEvent(),
            getPurchaseEvent(
                advertisementGtmItem,
                0,
                0.2,
            ),
        );
        rtbEventMobile(advertisementId);

    }, [
        livemonitorSendEvent,
        phoneNumberLmEventNskParams,
        phoneNumberLmEventPltParams,
        sendFacebookPixelEvent,
        gtmSendEvent,
        advertisementGtmItem,
        advertiser.id,
        advertiser.name,
        advertiser.agency?.name,
        position,
        devProjectId,
        isAdvertiserLoggedIn,
        isPhotoGallery,
        rtbEventMobile,
        advertisementId,
    ]);

    const onPhoneCopyButtonClick = useCallback(() => {
        gtmSendEvent(
            getClickPhoneNumberEvent(
                EPosition.UP,
                advertisementGtmItem.devProjectId,
                isPhotoGallery,
            )
        );
    }, [advertisementGtmItem.devProjectId, gtmSendEvent, isPhotoGallery]);

    const onEmailButtonClick = useCallback(() => {
        sendFacebookPixelEvent(['track', 'Contact']);
        gtmSendEvent(
            getGenerateLeadEvent(
                advertisementId,
                advertiser.id,
                advertiser.name,
                EFormState.PROGRESSED,
                EFormStep.STEP_1,
                EMethod.SQL_SEND_EMAIL,
                position,
                advertiser.agency?.name,
                devProjectId,
                isAdvertiserLoggedIn,
                isPhotoGallery,
            ),
            getAddToCartEvent(
                advertisementGtmItem,
                0,
                1,
            )
        );

    }, [
        sendFacebookPixelEvent,
        gtmSendEvent,
        advertisementId,
        advertiser.id,
        advertiser.name,
        advertiser.agency?.name,
        position,
        devProjectId,
        isAdvertiserLoggedIn,
        isPhotoGallery,
        advertisementGtmItem,
    ]);

    const onInspectionButtonClick = useCallback(() => {
        sendFacebookPixelEvent(['track', 'Contact']);
        gtmSendEvent(
            getGenerateLeadEvent(
                advertisementId,
                advertiser.id,
                advertiser.name,
                EFormState.PROGRESSED,
                EFormStep.STEP_1,
                EMethod.SQL_SCHEDULE_VIEWING,
                position,
                advertiser.agency?.name,
                devProjectId,
                isAdvertiserLoggedIn,
                isPhotoGallery,
            ),
            getAddToCartEvent(
                advertisementGtmItem,
                0,
                1,
            )
        );
    }, [
        sendFacebookPixelEvent,
        gtmSendEvent,
        advertisementId,
        advertiser.id,
        advertiser.name,
        advertiser.agency?.name,
        position,
        devProjectId,
        isAdvertiserLoggedIn,
        isPhotoGallery,
        advertisementGtmItem,
    ]);

    const onSwitchButtons = (): void => {
        const activeContactType = contactType === AdvertiserContactTypeEnum.INSPECTION
            ? AdvertiserContactTypeEnum.MORE_INFO
            : AdvertiserContactTypeEnum.INSPECTION;

        setContactType(activeContactType);
        if (activeContactType === AdvertiserContactTypeEnum.MORE_INFO) {
            resetFormText();
        }
        if (activeContactType === AdvertiserContactTypeEnum.INSPECTION) {
            setFormText('Dobrý deň, mám záujem o obhliadku ponúkanej nehnuteľnosti.');
        }
    };

    return (
        <>
            <Box mb={2}>
                <Phone
                    phoneNum={phoneNumber}
                    isMobile={isMobile}
                    advertisementId={advertisementId}
                    advertiserId={advertiserId}
                    onShowButtonClick={onShowPhoneNumber}
                    onCopyPhoneNumberButtonClick={onPhoneCopyButtonClick}
                />
            </Box>

            <Box mb={2} sx={{borderColor: theme.palette.dividerTransparent}}>
                <Divider>
                    <Text
                        variant={'breadcrumb'}
                    >
                        Alebo
                    </Text>
                </Divider>
            </Box>

            <Box mb={1}>
                <SwitchButtons
                    uniformWidth
                    options={[
                        {name: 'E-mail', value: AdvertiserContactTypeEnum.MORE_INFO},
                        {name: 'Obhliadka', value: AdvertiserContactTypeEnum.INSPECTION}
                    ]}
                    activeOptionValue={contactType}
                    onChange={onSwitchButtons}
                />
            </Box>
            <Box mb={1}>
                <Grid item>
                    <TextArea
                        rows={3}
                        value={formText}
                        errMsg={formTextError && 'Povinné'}
                        onChange={(value) => setFormText(value)}
                    />
                </Grid>
            </Box>
            <Box>
                <ContactButton
                    simplifiedContactModal
                    btnText={'Odoslať správu'}
                    disabled={formTextError}
                    advertisementId={advertisementId}
                    onButtonClick={() => {
                        if (contactType === AdvertiserContactTypeEnum.MORE_INFO) {
                            onEmailButtonClick();
                        }
                        if (contactType === AdvertiserContactTypeEnum.INSPECTION) {
                            onInspectionButtonClick();
                        }
                    }}
                    type={contactType}
                    advertisementGtmItem={advertisementGtmItem}
                    lmEventNskParams={emailButtonLmEventNskParams}
                    lmEventPltParams={emailButtonLmEventPltParams}
                    isPhotoGallery={isPhotoGallery}
                    formPosition={position}
                />
            </Box>
        </>
    );
};
