import {FC} from 'react';
import {useTheme} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';
import {PromoBanner} from 'components/page/advertisement/detail/components/mainContent/promoBanner/common/PromoBanner';
import Icon from 'modules/theme/components/icons/basic/Calculator.svg';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

interface IDstiPromoBannerProps {
    onCLick?: () => void;
}

export const DstiPromoBanner: FC<IDstiPromoBannerProps> = ({onCLick}) => {
    const {palette} = useTheme();

    return <PromoBanner
        icon={Icon}
        ctaSubpageHref="vypocet-hypoteky"
        iconBgColor={palette.identityBasicBlue.main}
        text="Overte si, akú hypotéku môžete získať."
        ctaText={(
            <>
                <Mobile>
                    <Text underLine variant='body2' noWrap>
                        Vypočítať
                    </Text>

                </Mobile>
                <Desktop>
                    <Text underLine variant='body2' noWrap>
                        Vypočítať hypotéku
                    </Text>
                </Desktop>
            </>
        )}
        onClick={onCLick}
    />;
};
