import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { EAdvertiserType } from 'modules/api/clients/advertisement/detail/enums/advertiser/EAdvertiserType';

interface IProps {
    id: string;
    slugName: string;
    name: string;
    photoUrl?: string;
    type: EAdvertiserType;
    isPro: boolean;
    advertisementId: string;
    agencyName?: string;
}

export const SimplifiedAdvertiserProfile: FC<IProps> = ({
    name,
    type,
    agencyName,
}) => {
    const isPrivatePerson = type === EAdvertiserType.PRIVATE_PERSON;
    const advertiserType = isPrivatePerson
        ? 'Súkromná osoba'
        : agencyName;

    return (
        <Stack flexDirection={'row'}>
            <Box width={'100%'}>
                <Box>
                    <Text variant={'label1'} semibold>
                        {name}
                    </Text>
                </Box>
                <Box mb={1}>
                    <Text variant='label2'>{advertiserType}</Text>
                </Box>
            </Box>
        </Stack>
    );
};
