import { FC } from 'react';
import { Stack } from '@mui/material';
import { ECategoriesLabel } from 'components/page/advertisement/detail/enums/heading/category/ECategoriesLabel';
import { Location } from 'components/page/advertisement/detail/components/mainContent/heading/partials/parameters/partials/location/Location';
import { Parameters as ParameterList } from 'components/page/advertisement/detail/components/mainContent/heading/partials/parameters/partials/parameters/Parameters';

interface IParameters {
    area?: string;
    location?: string;
    category: ECategoriesLabel;
    realEstateState?: string;
}

export const Parameters: FC<IParameters> = ({
    category,
    location,
    area,
    realEstateState,
}) => {
    return (
        <Stack gap={1}>
            <Location location={location} />
            <ParameterList
                category={category}
                area={area}
                state={realEstateState}
            />
        </Stack>
    );
};
