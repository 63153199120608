import React, {FC, useEffect} from 'react';
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import Map from 'components/map/MapWithoutSSR';
import { Title } from 'components/page/advertisement/detail/components/mainContent/location/partials/title/Title';
import LocationPin from 'components/page/advertisement/detail/assets/icons/PointerIcon.svg?url';
import { useWasInView } from 'modules/view/hooks/useWasInView';

interface ILocation {
    lat: number;
    lng: number;
    onMapView?: () => void;
}

export const Location: FC<ILocation> = ({ lat, lng, onMapView }) => {
    const { wasInView, ref } = useWasInView();

    const position = { latitude: lat, longitude: lng };
    const theme: Theme = useTheme();

    // TODO DODO zoomControl needed or refactor
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    useEffect(() => {
        if (!wasInView) {
            return;
        }
        onMapView();
    }, [onMapView, wasInView]);

    return (
        <>
            <Box mb={2}>
                <Title />
            </Box>
            <Box height={(theme) => theme.spacing(isMobile ? 25 : 37.5)} ref={ref}>
                <Map
                    scrollWheelZoom={false}
                    zoomControlPosition={'bottomright'}
                    center={position}
                    mapBorderRadius={theme.spacing(3)}
                    markers={[
                        {
                            icon: {
                                url: LocationPin.src,
                                size: {
                                    width: 80,
                                    height: 80,
                                },
                            },
                            location: position,
                        },
                    ]}
                    zoomControl={!isMobile}
                    fullScreenControlPosition={'topright'}
                    fullScreenControl
                />
            </Box>
        </>
    );
};
