import {ECategory} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ECategory';
import {EMainCategory} from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';

export const CategoriesEnumsRecord: Record<EMainCategory, ECategory> = {
    [EMainCategory.REAL_ESTATES]: ECategory.REAL_ESTATES,
    [EMainCategory.APARTMENTS]: ECategory.APARTMENTS,
    [EMainCategory.HOUSES]: ECategory.HOUSES,
    [EMainCategory.SECONDARY_HOUSES]: ECategory.HOUSES,
    [EMainCategory.SPACES]: ECategory.SPACES,
    [EMainCategory.LANDS]: ECategory.LANDS,
    [EMainCategory.SECONDARY_LANDS]: ECategory.LANDS,
    [EMainCategory.OBJECTS]: ECategory.OBJECTS,
    [EMainCategory.COTTAGES_AND_CABINS]: ECategory.COTTAGES_AND_CABINS,
    [EMainCategory.SECONDARY_SPACES_AND_OBJECTS]: ECategory.SECONDARY_SPACES_AND_OBJECTS,
    [EMainCategory.SECONDARY_RECREATIONAL_PROPERTIES]: ECategory.SECONDARY_RECREATIONAL_PROPERTIES,
};
