import React, { FC } from 'react';
import Link from 'components/link/Link';
import { advertisingConditions } from 'modules/common/links/advertisingConditions/advertisingConditions';

export const LinkToAC: FC = () => {
    return (
        <Link
            href={
                advertisingConditions
            }
            openInNewTab
            sx={{ textDecoration: 'underline' }}
        >
            Všeobecnými obchodnými podmienkami
        </Link>
    );
};
