import React, { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { LinkToAC } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/common/checkboxDisclaimer/partials/linkToAC/LinkToAC';
import { LinkToGdpr } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/common/checkboxDisclaimer/partials/linkToGdpr/LinkToGdpr';

export const CheckboxDisclaimer: FC = () => {
    return (
        <Text variant={'label2'} color={(palette) => palette.labelSecondary}>
            Súhlasím so <LinkToAC /> a vyhlasujem, že som sa oboznámil/a s <LinkToGdpr />.
        </Text>
    );
};
