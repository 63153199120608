import {FC} from 'react';
import {Box, Theme} from '@mui/material';
import {
    IAdvertiser
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/advertiser/IAdvertiser';
import {IPriceInfo} from 'modules/api/clients/advertisement/common/interfaces/price/IPriceInfo';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import {
    ButtonsBox
} from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStatic/partials/buttonsBox/ButtonsBox';
import {EPosition} from 'modules/gtmEvents/enums/advertisement/detail/EPosition';

interface IStickyBottomContent {
    advertisementId: string;
    advertiser: IAdvertiser;
    price?: IPriceInfo;
    isGallery?: boolean;
    advertisementGtmItem: IAdvertisementGtmItem;
    isPhotoGallery?: boolean;
}

export const StickyBottomContent: FC<IStickyBottomContent> = ({
    advertiser,
    //price,
    isGallery,
    advertisementId,
    advertisementGtmItem,
    isPhotoGallery,
}) => {
    return (
        <Box
            bottom={0}
            width='100%'
            zIndex={1300}
            position={isGallery ? 'fixed': 'sticky'}
            boxShadow={'0px -2px 8px 0px rgba(0, 0, 0, 0.08)'}
            borderRadius={(theme) => theme.spacing(3, 3, 0, 0)}
            padding={(theme) => theme.spacing(2.5, 2)}
            sx={{
                background: (theme: Theme) =>
                    theme.palette.backgroundPrimary.main,
            }}
        >
            <ButtonsBox
                mobileVersion
                advertiserId={advertiser.id}
                advertiserPhoneNumber={advertiser.mainPhone}
                isOpenedFromAgentContactModal
                advertisementId={advertisementId}
                advertisementGtmItem={advertisementGtmItem}
                isPhotoGallery={isPhotoGallery}
                position={EPosition.STICKY}
            />
            {/*<PriceAndContact*/}
            {/*    advertiser={advertiser}*/}
            {/*    price={price}*/}
            {/*    advertisementId={advertisementId}*/}
            {/*    advertisementGtmItem={advertisementGtmItem}*/}
            {/*/>*/}
        </Box>
    );
};
