import { FC } from 'react';
import { Stack, Box } from '@mui/material';
import { IAttribute } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/parameters/partials/attribute/IAttribute';
import { ShowMore } from 'components/page/advertisement/detail/components/mainContent/detailParameters/partials/realEstateProperties/partials/ShowMore';
import { Title } from 'components/page/advertisement/detail/components/mainContent/detailParameters/partials/realEstateProperties/partials/title/Title';
import { Properties } from 'components/page/advertisement/detail/components/mainContent/detailParameters/partials/realEstateProperties/partials/Properties';
import { ReportAdvertisement } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/ReportAdvertisement';
import { Mobile } from 'modules/theme/components/responsive';

interface IRealEstateProperties {
    attributes: IAttribute[];
    advertisementId: string;
}

const NUMBER_OF_PROPERTIES = 6;

export const RealEstateProperties: FC<IRealEstateProperties> = ({
    attributes,
    advertisementId,
}) => {
    const isMaxAttributesCount = attributes.length > 6;

    const firstAttributes = attributes.slice(0, NUMBER_OF_PROPERTIES);
    return (
        <Stack direction="column" gap={2}>
            <Title title={'Vlastnosti nehnuteľnosti'} />
            <Properties attributes={firstAttributes} />
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={{ xs: 'space-between', md: 'flex-start' }}
                width={'100%'}
            >
                {isMaxAttributesCount &&
                    <ShowMore attributes={attributes} />
                }
                <Mobile>
                    <ReportAdvertisement
                        id={advertisementId}
                        btnText={'Nahlásiť'}
                    />
                </Mobile>
            </Box>
        </Stack>
    );
};
