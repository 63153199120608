import {FC} from 'react';
import {
    IPhoto
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/photo/IPhoto';
import {
    IVideo
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/video/IVideo';
import {
    IFloorPlan
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/floorPlan/IFloorPlan';
import {
    IInspection3d
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/Inspection3d/IInspection3d';
import {IFlags} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/flags/IFlags';
import {PlentyOfPhotos} from 'components/page/advertisement/detail/components/common/photos/partials/PlentyOfPhotos';
import {MainPhoto} from 'components/page/advertisement/detail/components/common/photos/partials/mainPhoto/MainPhoto';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import {getPhotoAlt} from 'components/page/advertisement/detail/helpers/photos/GetPhotoAlt';
import {EGalleryGroupKey} from 'components/gallery/enums/group/EGalleryGroupKey';

interface IPhotos {
    slug: string;
    flags: IFlags;
    photos: IPhoto[];
    videos: IVideo[];
    isDevProject: boolean;
    advertisementId: string;
    floorPlans: IFloorPlan[];
    isPlentyOfPhotos: boolean;
    inspections3d: IInspection3d[];
    advertisementGtmItem: IAdvertisementGtmItem;
    openGallery: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
}

export const Photos: FC<IPhotos> = ({
    slug,
    flags,
    photos,
    videos,
    floorPlans,
    openGallery,
    isDevProject,
    inspections3d,
    advertisementId,
    isPlentyOfPhotos,
    advertisementGtmItem,
}) => {
    return (
        <>
            {isPlentyOfPhotos ? (
                <PlentyOfPhotos
                    slug={slug}
                    flags={flags}
                    photos={photos}
                    openGallery={openGallery}
                    areVideos={!!videos.length}
                    isDevProject={isDevProject}
                    advertisementId={advertisementId}
                    isPlentyOfPhotos={isPlentyOfPhotos}
                    areFloorPlans={!!floorPlans.length}
                    isInspection3d={!!inspections3d.length}
                    isSidePhotosVisible={isPlentyOfPhotos}
                    advertisementGtmItem={advertisementGtmItem}
                />
            ) : (
                <MainPhoto
                    alt={getPhotoAlt(slug, 1)}
                    flags={flags}
                    photos={photos}
                    isBordered={true}
                    openGallery={openGallery}
                    areVideos={!!videos.length}
                    isDevProject={isDevProject}
                    advertisementId={advertisementId}
                    isPlentyOfPhotos={isPlentyOfPhotos}
                    areFloorPlans={!!floorPlans.length}
                    isInspection3d={!!inspections3d.length}
                    advertisementGtmItem={advertisementGtmItem}
                />
            )}
        </>
    );
};
