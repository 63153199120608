import { FC } from 'react';
import { IAdvertiser } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/advertiser/IAdvertiser';
import { AdvertiserBoxOld as DesktopStickyAdvertiserBoxOld } from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStickyOld/AdvetiserBoxOld';
import { IAdvertisementGtmItem } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import { EPosition } from 'modules/gtmEvents/enums/advertisement/detail/EPosition';

interface IAdvertiserProps {
    advertiser: IAdvertiser;
    advertisementId: string;
    advertisementGtmItem: IAdvertisementGtmItem;
    isPhotoGallery?: boolean;
    isMobile: boolean;
    isPlentyOfPhotos?: boolean;
}

export const Advertiser: FC<IAdvertiserProps> = ({
    advertiser,
    advertisementId,
    advertisementGtmItem,
    isPhotoGallery,
    isMobile,
    isPlentyOfPhotos
}) => {
    return (
        <DesktopStickyAdvertiserBoxOld
            advertiser={advertiser}
            advertisementId={advertisementId}
            advertisementGtmItem={advertisementGtmItem}
            isPhotoGallery={isPhotoGallery}
            position={EPosition.UP}
            isMobile={isMobile}
            isPlentyOfPhotos={isPlentyOfPhotos}
        />
    );
};
