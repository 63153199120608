import {FC, useCallback} from 'react';
import {Box} from '@mui/material';
import {Title} from 'components/page/advertisement/detail/components/mainContent/moreInfoBox/partials/title/Title';
import {
    Description
} from 'components/page/advertisement/detail/components/mainContent/moreInfoBox/partials/description/Description';
import {
    AdvertiserContactTypeEnum
} from 'components/page/advertisement/detail/enums/advertiser/AdvertiserContactTypeEnum';
import {
    ContactButton
} from 'components/page/advertisement/detail/components/common/advertiser/partials/common/contactButton/ContactButton';
import {
    IconBox
} from 'components/page/advertisement/detail/components/mainContent/moreInfoBox/partials/iconBox/IconBox';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import {
    getAddToCartEvent,
    getGenerateLeadEvent,
} from 'modules/gtmEvents/helpers/events/advertisement/common/advertisementEventsHelper';
import {EFormState} from 'modules/gtmEvents/enums/EFormState';
import {EFormStep} from 'modules/gtmEvents/enums/EFormStep';
import {EMethod} from 'modules/gtmEvents/enums/EMethod';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import useSession from 'modules/state/app/hook/session/useSession';
import {EPosition} from 'modules/gtmEvents/enums/advertisement/detail/EPosition';

interface IMoreInfoBox {
    advertisementId: string;
    advertisementGtmItem: IAdvertisementGtmItem;
}

export const MoreInfoBox: FC<IMoreInfoBox> = ({ advertisementId, advertisementGtmItem }) => {
    const { session } = useSession();
    const isAdvertiserLoggedIn = !!session;

    const {
        facebookPixel: { sendEvent: sendFacebookPixelEvent },
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const onButtonClick = useCallback(() => {
        sendFacebookPixelEvent(['track', 'Contact']);
        gtmSendEvent(
            getGenerateLeadEvent(
                advertisementGtmItem.id,
                advertisementGtmItem.advertiser.id,
                advertisementGtmItem.advertiser.name,
                EFormState.PROGRESSED,
                EFormStep.STEP_1,
                EMethod.SQL_SCHEDULE_VIEWING,
                EPosition.MIDDLE,
                advertisementGtmItem.advertiser?.agency?.name,
                advertisementGtmItem.devProjectId,
                isAdvertiserLoggedIn,
            ),
            getAddToCartEvent(
                advertisementGtmItem,
                0,
                1,
            )
        );
    }, [sendFacebookPixelEvent, gtmSendEvent, advertisementGtmItem, isAdvertiserLoggedIn]);

    return (
        <>
            <Box mb={{ xs: 2, md: 1 }}>
                <Title />
            </Box>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={{ xs: 'flex-end', md: 'center' }}
            >
                <Box>
                    <Box mb={{ xs: 2 }}>
                        <Description />
                    </Box>
                    <ContactButton
                        fullWidth={false}
                        btnText={'Napísať správu'}
                        advertisementId={advertisementId}
                        type={AdvertiserContactTypeEnum.INSPECTION}
                        onButtonClick={onButtonClick}
                        advertisementGtmItem={advertisementGtmItem}
                        lmEventNskParams={['trackEvent', 'Advert', 'Contact-obhliadka', 'Mail']}
                        lmEventPltParams={['trackEvent', 'advert-contact-obhliadka-nehnutelnostisk', advertisementId, 'Mail']}
                        formPosition={EPosition.MIDDLE}
                    />
                </Box>
                <Box mr={{ xs: 0, md: 5 }} height={(theme) => theme.spacing(9)}>
                    <IconBox />
                </Box>
            </Box>
        </>
    );
};
