import { FC } from 'react';
import { Badge } from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/badge/partials/Badge';
import { EBadgeType } from 'modules/theme/components/listing/cards/advertisementCard/enums/badge/EBadgeType';

interface IAdvertiserTypeBadge {
    type: EBadgeType;
}

export const AdvertiserTypeBadge: FC<IAdvertiserTypeBadge> = ({ type }) => {
    return <Badge type={type} />;
};
