import {FC} from 'react';
import {Theme, useMediaQuery} from '@mui/material';
import {Button} from 'modules/theme/components/button/Button';
import useDialog from 'modules/dialog/useDialog';
import {
    ContactModal
} from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/ContactModal';
import {
    AdvertiserContactTypeEnum
} from 'components/page/advertisement/detail/enums/advertiser/AdvertiserContactTypeEnum';
import { IAdvertisementGtmItem } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import { EPosition } from 'modules/gtmEvents/enums/advertisement/detail/EPosition';
import { SimplifiedModal } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/partials/desktop/SimplifiedModal';

interface IContactButton {
    type: AdvertiserContactTypeEnum;
    btnText: string;
    advertisementId: string;
    tertiary?: boolean;
    fullWidth?: boolean;
    isOpenedFromAgentContactModal?: boolean;
    onButtonClick?: () => void;
    advertisementGtmItem: IAdvertisementGtmItem;
    formPosition: EPosition;
    lmEventPltParams?: string[];
    lmEventNskParams?: string[];
    isPhotoGallery?: boolean;
    simplifiedContactModal?: boolean;
    disabled?: boolean;
}

export const ContactButton: FC<IContactButton> = ({
    type,
    btnText,
    advertisementId,
    tertiary,
    fullWidth = true,
    onButtonClick,
    isOpenedFromAgentContactModal,
    advertisementGtmItem,
    lmEventPltParams,
    lmEventNskParams,
    isPhotoGallery,
    formPosition,
    simplifiedContactModal,
    disabled,
}) => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    let contactModalComponent = <ContactModal
        type={type}
        onClose={() => hideDialog()}
        advertisementId={advertisementId}
        isOpenedFromAgentContactModal={isOpenedFromAgentContactModal}
        advertisementGtmItem={advertisementGtmItem}
        lmEventPltParams={lmEventPltParams}
        lmEventNskParams={lmEventNskParams}
        isPhotoGallery={isPhotoGallery}
        formPosition={formPosition}
    />;
    if (simplifiedContactModal) {
        contactModalComponent = <SimplifiedModal
            type={type}
            onClose={() => hideDialog()}
            advertisementId={advertisementId}
            advertisementGtmItem={advertisementGtmItem}
            lmEventPltParams={lmEventPltParams}
            lmEventNskParams={lmEventNskParams}
            isPhotoGallery={isPhotoGallery}
            formPosition={formPosition}
        />;
    }

    const {
        component: modal,
        show: showDialog,
        hide: hideDialog,
    } = useDialog({
        content: contactModalComponent,
        paperProps: {
            sx: {
                borderRadius: (theme) => ({
                    sx: theme.spacing(2, 2, 0, 0),
                    md: theme.spacing(2),
                }),
            },
        },
        dialogContentProps: {
            sx: {
                padding: {xs: 3, md: 4},
            },
        },
        wrapContentIntoBox: false,
        fullScreen: isMobile,
        fullWidth: isMobile,
        heightFitContent: isMobile,
        positionBottom: isMobile,
    });

    return (
        <>
            <Button
                disabled={disabled}
                text={btnText}
                fullWidth={fullWidth}
                onClick={() => {
                    if (typeof onButtonClick === 'function') {
                        onButtonClick();
                    }
                    showDialog();
                }}
                tertiary={tertiary}
            />
            {modal}
        </>
    );
};
