import { FC } from 'react';
import { Grid } from '@mui/material';
import { Desktop, Mobile } from 'modules/theme/components/responsive';
import { IPhoto } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/photo/IPhoto';
import { SidePhotos } from 'components/page/advertisement/detail/components/common/photos/partials/sidePhotos/SidePhotos';
import { MainPhoto } from 'components/page/advertisement/detail/components/common/photos/partials/mainPhoto/MainPhoto';
import { IFlags } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/flags/IFlags';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';
import { IAdvertisementGtmItem } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import { getPhotoAlt } from 'components/page/advertisement/detail/helpers/photos/GetPhotoAlt';

interface IPlentyOfPhotos {
    slug: string;
    flags: IFlags;
    photos: IPhoto[];
    areVideos?: boolean;
    isDevProject: boolean;
    advertisementId: string;
    areFloorPlans?: boolean;
    isInspection3d?: boolean;
    isPlentyOfPhotos: boolean;
    isSidePhotosVisible: boolean;
    openGallery: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
    advertisementGtmItem: IAdvertisementGtmItem;
}

export const PlentyOfPhotos: FC<IPlentyOfPhotos> = ({
    slug,
    flags,
    photos,
    areVideos,
    openGallery,
    isDevProject,
    areFloorPlans,
    isInspection3d,
    advertisementId,
    isPlentyOfPhotos,
    isSidePhotosVisible,
    advertisementGtmItem,
}) => {
    return (
        <>
            <Desktop>
                <Grid container spacing={1} height='100%'>
                    <Grid item xs={isSidePhotosVisible ? 8 : 12} position='relative'>
                        <MainPhoto
                            alt={getPhotoAlt(slug, 1)}
                            flags={flags}
                            photos={photos}
                            areVideos={areVideos}
                            openGallery={openGallery}
                            isDevProject={isDevProject}
                            areFloorPlans={areFloorPlans}
                            isInspection3d={isInspection3d}
                            advertisementId={advertisementId}
                            isPlentyOfPhotos={isPlentyOfPhotos}
                            advertisementGtmItem={advertisementGtmItem}
                            isBordered={isSidePhotosVisible ? false : true}

                        />
                    </Grid>

                    {isSidePhotosVisible && (
                        <Grid item xs={4} height='100%'>
                            <SidePhotos
                                slug={slug}
                                photos={photos}
                                openGallery={openGallery}
                            />
                        </Grid>
                    )}

                </Grid>
            </Desktop>

            <Mobile>
                <Grid item xs={12}>
                    <MainPhoto
                        alt={getPhotoAlt(slug, 1)}
                        flags={flags}
                        photos={photos}
                        isBordered={false}
                        areVideos={areVideos}
                        openGallery={openGallery}
                        isDevProject={isDevProject}
                        areFloorPlans={areFloorPlans}
                        isInspection3d={isInspection3d}
                        advertisementId={advertisementId}
                        isPlentyOfPhotos={isPlentyOfPhotos}
                        advertisementGtmItem={advertisementGtmItem}

                    />
                </Grid>
            </Mobile>
        </>
    );
};
