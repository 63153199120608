import { ReactNode } from 'react';
import {useRouter} from 'next/navigation';
import {useMediaQuery, Theme} from '@mui/material';
import { IGroup } from 'components/gallery/interfaces/group/IGroup';
import {
    IAdvertisementDetail
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/IAdvertisementDetail';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import {useDetailGallery} from 'components/page/advertisement/detail/hooks/gallery/useDetailGallery';
import {ADVERTISEMENT_DETAIL, ADVERTISEMENT_DETAIL_WITH_GALLERY} from 'modules/route/routes';
import { galleryEGroupKeyToGroupUrlRecord } from 'components/gallery/records/galleryEGroupKeyToGroupUrlRecord';
import { StickyBottomContent } from 'components/page/advertisement/detail/components/layout/StickyBottomContent';
import { StickyLayoutContent } from 'components/page/advertisement/detail/components/layout/StickyContent';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';

interface IReturn {
    gallery: ReactNode;
    openGallery: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
}


export const useMainLayoutGallery = (
    galleryGroup: IGroup,
    isGalleryOpen: boolean,
    advertisementSlug: string,
    advertisementGtmItem: IAdvertisementGtmItem,
    advertisement: IAdvertisementDetail,
): IReturn => {
    const router = useRouter();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const onOpenRedirectCallback = (groupKey: EGalleryGroupKey): void => {
        router.replace(ADVERTISEMENT_DETAIL_WITH_GALLERY(
            advertisement.id,
            advertisementSlug,
            galleryEGroupKeyToGroupUrlRecord[groupKey],
        ), { scroll: false });
    };

    const onChangeRedirectCallback = (groupKey: EGalleryGroupKey): void => {
        router.replace(ADVERTISEMENT_DETAIL_WITH_GALLERY(
            advertisement.id,
            advertisementSlug,
            galleryEGroupKeyToGroupUrlRecord[groupKey],
        ), { scroll: false });
    };

    const onCloseRedirectCallback = (): void => {
        router.replace(ADVERTISEMENT_DETAIL(
            advertisement.id,
            advertisementSlug,
        ), { scroll: false });
    };

    const gallerySideContent = isMobile ? (
        <StickyBottomContent
            isGallery
            advertisementId={advertisement.id}
            advertiser={advertisement.advertiser}
            advertisementGtmItem={advertisementGtmItem}
            isPhotoGallery
        />
    ) : (
        <StickyLayoutContent
            topPosition={10}
            advertisementId={advertisement.id}
            advertiser={advertisement.advertiser}
            advertisementGtmItem={advertisementGtmItem}
            isPhotoGallery
        />
    );

    const {
        gallery,
        openGallery,
    } = useDetailGallery(
        isGalleryOpen,
        advertisement.parameters.title,
        advertisement.media.photos,
        advertisement.media.videos,
        advertisement.id,
        advertisement.media.floorPlans,
        advertisementSlug,
        advertisement.media.inspections3d,
        onOpenRedirectCallback,
        onCloseRedirectCallback,
        onChangeRedirectCallback,
        galleryGroup.key,
        gallerySideContent,
    );

    return {
        gallery,
        openGallery,
    };
};