import { FC, useMemo } from 'react';
import { IAdvertisementGtmItem } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import { mapIAdvertisementDetailToGtmItemMapper } from 'modules/api/clients/advertisement/detail/helpers/mappers/advertisementDetail/mapIAdvertisementDetailToGtmItemMapper';
import { IAdvertisementDetail } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/IAdvertisementDetail';

import { AdvertiserBox as DesktopStaticAdvertiserBox } from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStatic/AdvertiserBox';

interface IAdvertiser {
    advertisement: IAdvertisementDetail;
    lmEventPltParams?: string[];
    lmEventNskParams?: string[];
}

export const AdvertiserBox: FC<IAdvertiser> = ({
    advertisement,
    lmEventPltParams,
    lmEventNskParams,
}) => {
    const { advertiser, id: advertisementId } = advertisement;

    const advertisementGtmItem: IAdvertisementGtmItem = useMemo(
        () => mapIAdvertisementDetailToGtmItemMapper(advertisement),
        [advertisement]
    );

    return (
        <DesktopStaticAdvertiserBox
            advertiser={advertiser}
            advertisementId={advertisementId}
            lmEventPltParams={lmEventPltParams}
            lmEventNskParams={lmEventNskParams}
            advertisementGtmItem={advertisementGtmItem}
        />
    );
};
