import {FC} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {EMethod} from 'modules/gtmEvents/enums/EMethod';
import {EFormStep} from 'modules/gtmEvents/enums/EFormStep';
import {Box, Grid} from '@mui/material';
import {Button} from 'modules/theme/components/button/Button';
import {EFormState} from 'modules/gtmEvents/enums/EFormState';
import useNotification from 'modules/notification/useNotification';
import useSession from 'modules/state/app/hook/session/useSession';
import {Input} from 'modules/theme/components/fields/input/Input';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import {TextArea} from 'modules/theme/components/fields/textArea/TextArea';
import {getResetEcommerceEvent} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import {InputsMergedWrapper} from 'modules/theme/components/fields/input/partials/InputsMergedWrapper';
import { CheckboxWithLabel } from 'modules/theme/components/fields/checkbox/CheckboxWithLabel';
import {useContactAdvertiser} from 'components/page/advertisement/detail/hooks/advertiser/useContactAdvertiser';
import {IContactAdvertiser} from 'components/page/advertisement/detail/interfaces/advertiser/IContactAdvertiser';
import {
    AdvertiserContactTypeEnum
} from 'components/page/advertisement/detail/enums/advertiser/AdvertiserContactTypeEnum';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import {
    getGenerateLeadEvent,
    getPurchaseEvent,
} from 'modules/gtmEvents/helpers/events/advertisement/common/advertisementEventsHelper';
import {
    getContactAdvertiserValidationSchema
} from 'components/page/advertisement/detail/helpers/advertiser/GetContactAdvertiserValidationSchema';
import {
    Title
} from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStatic/partials/form/partials/title/Title';
import {
    CheckboxDisclaimer
} from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/common/checkboxDisclaimer/CheckboxDisclaimer';
import {EPosition} from 'modules/gtmEvents/enums/advertisement/detail/EPosition';
import { useTracker } from 'modules/analytics/rtb/hooks/util/visit/useTracker';

interface IAdvertiserForm {
    advertisementId: string;
    advertisementGtmItem: IAdvertisementGtmItem;
    lmEventPltParams?: string[];
    lmEventNskParams?: string[];
}

export const AdvertiserContactForm: FC<IAdvertiserForm> = ({
    advertisementId,
    advertisementGtmItem,
    lmEventPltParams,
    lmEventNskParams,
}) => {
    const { advertiser, devProjectId } = advertisementGtmItem;

    const { session } = useSession();
    const isAdvertiserLoggedIn = !!session;

    const {
        gtm: { sendEvent: gtmSendEvent },
        livemonitor: { sendEvent: livemonitorSendEvent },
    } = useAnalytics();

    const { onSend, isLoading } = useContactAdvertiser(advertisementId);

    const { success: showSuccessFlashMessage, error: showErrorFlashMessage } =
        useNotification();

    const initFormValues: IContactAdvertiser = {
        text: 'Dobrý deň, kontaktujte ma, prosím, ohľadom informácií o ponúkaných nehnuteľnostiach.',
        mail: '',
        phoneNumber: '',
        name: '',
        type: AdvertiserContactTypeEnum.MORE_INFO,
        gdpr: false,
        // TODO set empty string when using recaptcha
        recaptchaToken: 'remove me when using recaptcha',
    };

    const {
        rtbEventEmail
    } = useTracker();

    return (
        <Box
            p={{ md: 4 }}
            borderRadius={{ md: 2 }}
            border={(theme) => ({
                // xs: 0,
                md: `1px solid ${theme.palette.dividerTransparent}`,
            })}
            boxShadow={(theme) => ({
                // xs: 0,
                md: `0px 2px 4px ${theme.palette.labelQuarternary.main}`,
            })}
        >
            <Formik
                validateOnMount
                validateOnChange
                initialValues={initFormValues}
                validationSchema={getContactAdvertiserValidationSchema()}
                onSubmit={(values: IContactAdvertiser, { resetForm }) => {
                    onSend(values).then((isSentSuccessfully) => {
                        if (isSentSuccessfully) {
                            showSuccessFlashMessage(
                                'Vaša správa bola odoslaná'
                            );
                            resetForm({ values: initFormValues });

                            if (lmEventPltParams) {
                                livemonitorSendEvent({
                                    p: lmEventPltParams,
                                    o: { pn: 'plt-stats' },
                                });
                            }
                            if (lmEventNskParams) {
                                livemonitorSendEvent({
                                    p: lmEventNskParams,
                                    o: { pn: 'nehnutelnosti' },
                                });
                            }

                            gtmSendEvent(
                                getGenerateLeadEvent(
                                    advertisementId,
                                    advertiser.id,
                                    advertiser.name,
                                    EFormState.SUCCESS,
                                    EFormStep.STEP_1,
                                    EMethod.SQL_SEND_EMAIL,
                                    EPosition.DOWN,
                                    advertiser?.agency?.name,
                                    devProjectId,
                                    isAdvertiserLoggedIn
                                ),
                                getResetEcommerceEvent(),
                                getPurchaseEvent(advertisementGtmItem, 0)
                            );

                            rtbEventEmail(advertisementId);
                        }

                        if (!isSentSuccessfully) {
                            showErrorFlashMessage(
                                'Správu sa nepodarilo odoslať'
                            );

                            gtmSendEvent(
                                getGenerateLeadEvent(
                                    advertisementId,
                                    advertiser.id,
                                    advertiser.name,
                                    EFormState.ERROR,
                                    EFormStep.STEP_1,
                                    EMethod.SQL_SEND_EMAIL,
                                    EPosition.DOWN,
                                    advertiser?.agency?.name,
                                    devProjectId,
                                    isAdvertiserLoggedIn
                                )
                            );
                        }
                    });
                }}
            >
                {(formikProps: FormikProps<IContactAdvertiser>) => (
                    <Form
                        noValidate
                        autoComplete="off"
                        onSubmit={formikProps.handleSubmit}
                    >
                        <Grid
                            container
                            spacing={{ xs: 2, md: 4 }}
                            flexDirection={{ xs: 'column', md: 'row' }}
                        >
                            <Grid
                                item
                                md={6}
                                container
                                xs={12}
                                rowGap={1}
                                direction="column"
                            >
                                <Grid item>
                                    <Title text="Správa predajcovi:" />
                                </Grid>

                                <Grid item>
                                    <TextArea
                                        rows={7}
                                        disabled={isLoading}
                                        value={formikProps.values.text}
                                        errMsg={
                                            formikProps.touched.text &&
                                            formikProps.errors.text
                                        }
                                        onChange={(value) =>
                                            formikProps.setFieldValue(
                                                'text',
                                                value
                                            )
                                        }
                                        onBlur={() =>
                                            formikProps.setFieldTouched('text')
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                container
                                rowGap={1}
                                direction="column"
                            >
                                <Grid item>
                                    <Title text="Vaše kontaktné údaje:" />
                                </Grid>

                                <Grid item>
                                    <InputsMergedWrapper>
                                        <Input
                                            disabled={isLoading}
                                            value={formikProps.values.name}
                                            label="Meno a priezvisko *"
                                            errorMsg={
                                                formikProps.touched.name &&
                                                formikProps.errors.name
                                            }
                                            onChange={(value) =>
                                                formikProps.setFieldValue(
                                                    'name',
                                                    value
                                                )
                                            }
                                            onBlur={() =>
                                                formikProps.setFieldTouched(
                                                    'name'
                                                )
                                            }
                                        />
                                        <Input
                                            label="Telefón *"
                                            disabled={isLoading}
                                            value={
                                                formikProps.values.phoneNumber
                                            }
                                            errorMsg={
                                                formikProps.touched
                                                    .phoneNumber &&
                                                formikProps.errors.phoneNumber
                                            }
                                            onChange={(value) =>
                                                formikProps.setFieldValue(
                                                    'phoneNumber',
                                                    value
                                                )
                                            }
                                            onBlur={() =>
                                                formikProps.setFieldTouched(
                                                    'phoneNumber'
                                                )
                                            }
                                        />
                                        <Input
                                            label="E-mail *"
                                            disabled={isLoading}
                                            value={formikProps.values.mail}
                                            errorMsg={
                                                formikProps.touched.mail &&
                                                formikProps.errors.mail
                                            }
                                            onChange={(value) =>
                                                formikProps.setFieldValue(
                                                    'mail',
                                                    value
                                                )
                                            }
                                            onBlur={() =>
                                                formikProps.setFieldTouched(
                                                    'mail'
                                                )
                                            }
                                        />
                                    </InputsMergedWrapper>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                container
                                gap={2}
                                flexWrap="nowrap"
                                alignItems={{ xs: 'end', md: 'center' }}
                                flexDirection={{ xs: 'column', md: 'row' }}
                            >
                                <Grid item order={{ xs: 2, md: 1 }} md="auto">
                                    <Button
                                        type="submit"
                                        disabled={
                                            isLoading || !formikProps.isValid
                                        }
                                        text="Odoslať správu"
                                    />
                                </Grid>

                                <Grid item order={{ xs: 1, md: 2 }}>
                                    <CheckboxWithLabel
                                        disabled={isLoading}
                                        isChecked={formikProps.values.gdpr}
                                        label={<CheckboxDisclaimer />}
                                        errMsg={
                                            formikProps.touched.gdpr &&
                                            formikProps.errors.gdpr
                                        }
                                        onClick={(value) =>
                                            formikProps.setFieldValue(
                                                'gdpr',
                                                value
                                            )
                                        }
                                        onBlur={() =>
                                            formikProps.setFieldTouched('gdpr')
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
