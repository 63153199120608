import { FC } from 'react';
import {Container, Box} from '@mui/material';
import {Mobile, Desktop} from 'modules/theme/components/responsive';
import {Photos} from 'components/page/advertisement/detail/components/common/photos/Photos';
import {
    IAdvertisementDetail
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/IAdvertisementDetail';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';

interface IMainPhotos {
    isPlentyOfPhotos: boolean;
    advertisement: IAdvertisementDetail;
    advertisementGtmItem: IAdvertisementGtmItem;
    openGallery: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
}

export const MainPhotos: FC<IMainPhotos> = ({
    openGallery,
    advertisement,
    isPlentyOfPhotos,
    advertisementGtmItem,
}) => {
    return <>
        <Desktop display='contents'>
            <Container>
                <Box
                    position='relative'
                    mb={{xs: 2, md: 3}}
                    maxHeight={{xs: 408, md: 'none'}}
                >
                    <Photos
                        openGallery={openGallery}
                        flags={advertisement.flags}
                        slug={advertisement.slugTitle}
                        advertisementId={advertisement.id}
                        isPlentyOfPhotos={isPlentyOfPhotos}
                        photos={advertisement.media.photos}
                        videos={advertisement.media.videos}
                        isDevProject={!!advertisement.devProject}
                        floorPlans={advertisement.media.floorPlans}
                        advertisementGtmItem={advertisementGtmItem}
                        inspections3d={advertisement.media.inspections3d}
                    />
                </Box>
            </Container>
        </Desktop>
        <Mobile display='contents'>
            <Box position='relative'>
                <Photos
                    openGallery={openGallery}
                    flags={advertisement.flags}
                    slug={advertisement.slugTitle}
                    advertisementId={advertisement.id}
                    isPlentyOfPhotos={isPlentyOfPhotos}
                    photos={advertisement.media.photos}
                    videos={advertisement.media.videos}
                    isDevProject={!!advertisement.devProject}
                    floorPlans={advertisement.media.floorPlans}
                    advertisementGtmItem={advertisementGtmItem}
                    inspections3d={advertisement.media.inspections3d}
                />
            </Box>
        </Mobile>
    </>;
};
