import { FC } from 'react';
import { Box } from '@mui/material';
import { IParameters } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/parameters/ParametersDto';
import { LandingParameters } from 'components/page/advertisement/detail/components/mainContent/detailParameters/partials/landingParameters/LandingParameters';
import { RealEstateProperties } from 'components/page/advertisement/detail/components/mainContent/detailParameters/partials/realEstateProperties/RealEstateProperties';

interface IDetailParameters {
    parameters: IParameters;
    advertisementId: string;
}

export const DetailParameters: FC<IDetailParameters> = ({
    parameters,
    advertisementId,
}) => {
    const areAttributes =
        parameters.attributes && parameters.attributes.length > 0;

    return (
        <>
            <Box mb={3}>
                <LandingParameters
                    category={parameters.category.mainValue}
                    area={parameters?.area}
                    floor={parameters?.floor}
                    title={parameters.title}
                    hasElevator={parameters.hasElevator}
                    construction={parameters?.construction}
                    totalFloors={parameters?.numberOfFloors}
                    realEstateState={parameters?.realEstateState}
                    yearOfConstruction={parameters?.yearOfConstruction}
                    energyRatingCertificate={
                        parameters?.energyRatingCertificate
                    }
                />
            </Box>
            {areAttributes && (
                <RealEstateProperties
                    attributes={parameters.attributes}
                    advertisementId={advertisementId}
                />
            )}
        </>
    );
};
