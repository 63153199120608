import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import Link from 'components/link/Link';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { ERouterTabs } from 'modules/route/enums/agent/detail/ERouterTabs';
import { REAL_ESTATE_AGENT_DETAIL_TAB } from 'modules/route/routes';
import { EAdvertiserType } from 'modules/api/clients/advertisement/detail/enums/advertiser/EAdvertiserType';
import { User } from 'modules/theme/components/assets/userBudges/user/User';
import { AdvertiserName } from 'components/page/advertisement/detail/components/common/advertiser/common/advertiserProfile/partials/advertiserName/AdvertiserName';
import { AdvertiserTypeBadge } from 'components/page/advertisement/detail/components/common/advertiser/common/advertiserProfile/partials/advertiserTypeBadge/AdvertiserTypeBadge';
import { AdvertiserTypeRecord } from 'components/page/advertisement/detail/constants/record/advertiser/badge/AdvertiserTypeRecord';
import { AdvertiserProfileTitle } from 'components/page/advertisement/detail/components/common/advertiser/common/advertiserProfileTitle/AdvertiserProfileTitle';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { getClickAgentInfoEvent } from 'modules/gtmEvents/helpers/events/advertisement/detail/advertisementDetailEventsHelper';
import useSession from 'modules/state/app/hook/session/useSession';

interface IAdvertiserProfile {
    id: string;
    slugName: string;
    name: string;
    photoUrl?: string;
    type: EAdvertiserType;
    isPro: boolean;
    advertisementId: string;
}

export const AdvertiserProfile: FC<IAdvertiserProfile> = ({
    name,
    photoUrl,
    type,
    isPro,
    id,
    slugName,
    advertisementId,
}) => {
    const { session } = useSession();
    const isAdvertiserLoggedIn = !!session;
    const isPrivatePerson = type === EAdvertiserType.PRIVATE_PERSON;

    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    // TODO - change ERouterTabs.ADVERTISEMENT to ERouterTabs.ABOUT after is available
    const agentDetailUrl = `${AppConfigService.getNehnutelnostiUrl()}${REAL_ESTATE_AGENT_DETAIL_TAB(
        id,
        slugName,
        ERouterTabs.ADVERTISEMENT
    )}`;

    return (
        <Stack flexDirection={'row'} columnGap={1}>
            <Box width={'100%'}>
                <Box mb={1.5} width={'fit-content'}>
                    <AdvertiserTypeBadge type={AdvertiserTypeRecord[type]} />
                </Box>
                <Box mb={1}>
                    <AdvertiserName name={name} />
                </Box>
                {!isPrivatePerson && (
                    <Link
                        href={agentDetailUrl}
                        noUnderline
                        onClick={() => {
                            gtmSendEvent(
                                getClickAgentInfoEvent(
                                    advertisementId,
                                    isAdvertiserLoggedIn
                                )
                            );
                        }}
                    >
                        <AdvertiserProfileTitle
                            title={'Profil realitného makléra'}
                        />
                    </Link>
                )}
            </Box>

            <User
                width={64}
                badge={isPro}
                badgeLeft={isPro}
                photoUrl={photoUrl}
            />
        </Stack>
    );
};
