import React, { FC } from 'react';
import { Box } from '@mui/material';
import { FormikProps, Form } from 'formik';
import { Button } from 'modules/theme/components/button/Button';
import { IOption } from 'modules/theme/components/fields/radioBox/interfaces/IOption';
import FirstStep from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/contactForm/mobile/partials/firstStep/FirstStep';
import SecondStep from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/contactForm/mobile/partials/secondStep/SecondStep';
import { IContactAdvertiser } from 'components/page/advertisement/detail/interfaces/advertiser/IContactAdvertiser';

interface IContactForm extends FormikProps<IContactAdvertiser> {
    disabled: boolean;
    options: IOption[];
    step: number;
    setStep: (step: number) => void;
}

export const ContactForm: FC<IContactForm> = ({
    errors,
    values,
    touched,
    disabled,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    options,
    step,
    setStep,
    isValid,
}) => {
    const steps = [
        <FirstStep
            key={'firstStep'}
            values={values}
            options={options}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            disabled={disabled}
        />,
        <SecondStep
            key={'secondStep'}
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            disabled={disabled}
        />,
    ];

    const isLastStep = step === steps.length - 1;
    const isFirstStepInvalid = !!errors.type || !!errors.text;

    return (
        <Box>
            <Form autoComplete="off">{steps[step]}</Form>
            <Box display={'flex'} width={'100%'} justifyContent={'flex-end'}>
                {isLastStep ? (
                    <Button
                        onClick={() => handleSubmit()}
                        type="submit"
                        disabled={disabled || !isValid}
                        text="Odoslať"
                    />
                ) : (
                    <Button
                        type="button"
                        disabled={disabled || isFirstStepInvalid}
                        text="Pokračovať"
                        onClick={() => {
                            setStep(step + 1);
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};
