import { FC } from 'react';
import { Box } from '@mui/material';
import { IFlags } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/flags/IFlags';
import { Badges } from 'components/page/advertisement/detail/components/common/photos/partials/photoOverlay/partials/topContent/partials/badges/Badges';
import { Buttons } from 'components/page/advertisement/detail/components/common/photos/partials/photoOverlay/partials/topContent/partials/buttons/Buttons';
import { useComparison } from 'components/comparison/hooks/useComparison';
import { useFavorite } from 'components/favorite/hooks/useFavorite';
import { IAdvertisementGtmItem } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { getResetEcommerceEvent } from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import { getAdvertisementAddToWishListEvent } from 'modules/gtmEvents/helpers/events/advertisement/common/advertisementEventsHelper';
import { EFavoriteActions } from 'components/favorite/enums/EFavoriteActions';

interface ITopContent {
    flags: IFlags;
    advertisementId: string;
    advertisementGtmItem: IAdvertisementGtmItem;
}

export const TopContent: FC<ITopContent> = ({
    flags,
    advertisementId,
    advertisementGtmItem,
}) => {
    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const {
        isFavorite,
        onAddOrDeleteFromFavorite,
        isLoading: isLoadingFavorite,
        loginModal: favoriteLoginModal,
    } = useFavorite(advertisementId);

    const {
        isInComparison,
        onAddOrDeleteFromComparison,
        isLoading: isLoadingComparison,
        loginModal: comparisonLoginModal,
    } = useComparison(advertisementId);

    return (
        <>
            <Box top={24} left={24} position="absolute">
                <Badges isTop={flags.isTop} isPremium={flags.isPremium} />
            </Box>

            <Box top={24} right={24} position="absolute">
                <Buttons
                    isFavorite={isFavorite}
                    isInComparison={isInComparison}
                    isLoadingFavorite={isLoadingFavorite}
                    isLoadingComparison={isLoadingComparison}
                    onAddOrDeleteFromFavorite={(action) => {
                        if (action === EFavoriteActions.ADD_TO_FAVORITE) {
                            gtmSendEvent(
                                getResetEcommerceEvent(),
                                getAdvertisementAddToWishListEvent(
                                    advertisementGtmItem
                                )
                            );
                        }
                        onAddOrDeleteFromFavorite(action);
                    }}
                    onAddOrDeleteFromComparison={onAddOrDeleteFromComparison}
                />
            </Box>

            {favoriteLoginModal}
            {comparisonLoginModal}
        </>
    );
};
