import {FC} from 'react';
import { Ad } from 'components/ads/components/ad/Ad';
import { AdSlot } from 'components/ads/enums/AdSlot';
import {Box, Theme, useMediaQuery} from '@mui/material';
import { Desktop } from 'modules/theme/components/responsive';
import {
    ReportAdvertisement
} from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/ReportAdvertisement';
import {
    IAdvertiser
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/advertiser/IAdvertiser';
import {Advertiser} from 'components/page/advertisement/detail/components/common/advertiser/Advertiser';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';

interface IStickyLayoutContent {
    topPosition?: number,
    advertiser: IAdvertiser;
    advertisementId: string;
    advertisementGtmItem: IAdvertisementGtmItem;
    isPhotoGallery?: boolean;
    isPlentyOfPhotos?: boolean;
}

export const StickyLayoutContent: FC<IStickyLayoutContent> = ({
    advertiser,
    advertisementId,
    advertisementGtmItem,
    topPosition= 20,
    isPhotoGallery,
    isPlentyOfPhotos
}) => {

    // TODO DODO Advertiser isMobile needed or refactor
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    return (
        <Box
            right={0}
            width="100%"
            zIndex={1501}
            maxWidth={378}
            display="flex"
            position="sticky"
            top={topPosition}
            flexDirection="column"
        >
            <Advertiser
                advertiser={advertiser}
                advertisementId={advertisementId}
                advertisementGtmItem={advertisementGtmItem}
                isPhotoGallery={isPhotoGallery}
                isMobile={isMobile}
                isPlentyOfPhotos={isPlentyOfPhotos}
            />

            <ReportAdvertisement
                id={advertisementId}
                btnText={'Nahlásiť ponuku'}
            />
            <Desktop>
                <Box my={2.5}>
                    <Ad slotDesktop={AdSlot.AD_DESKTOP_SQUARE} />
                </Box>
            </Desktop>
        </Box>
    );
};
