import { Ad } from 'components/ads/components/ad/Ad';
import { AdSlot } from 'components/ads/enums/AdSlot';
import { Box, Container, Divider } from '@mui/material';
import { FC, useCallback, useEffect } from 'react';
import { Mobile } from 'modules/theme/components/responsive';
import { Photos } from 'components/page/advertisement/detail/components/common/photos/Photos';
import { Heading } from 'components/page/advertisement/detail/components/mainContent/heading/Heading';
import { DstiPromoBanner } from 'components/page/advertisement/detail/components/mainContent/promoBanner/DstiPromoBanner';
import { IAdvertisementDetail } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/IAdvertisementDetail';
import { SubCategoriesToMainEnumsRecord } from 'components/page/advertisement/detail/constants/record/parameters/category/subCategoriesToMainEnumsRecord';
import { IAdvertisementGtmItem } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import { LandVerificationPromoBanner } from 'components/page/advertisement/detail/components/mainContent/promoBanner/LandVerificationPromoBanner';
import { DevProjectsPromoBanner } from 'components/page/advertisement/detail/components/mainContent/promoBanner/DevProjectsPromoBanner';
import { DetailParameters } from 'components/page/advertisement/detail/components/mainContent/detailParameters/DetailParameters';
import { PartOfDevProject } from 'components/page/advertisement/detail/components/mainContent/partOfDevProject/PartOfDevProject';
import {
    getAdvertisementViewItemEvent,
    getClickMapEvent,
    getTrackEvent,
} from 'modules/gtmEvents/helpers/events/advertisement/detail/advertisementDetailEventsHelper';
import { Description } from 'components/page/advertisement/detail/components/mainContent/description/Description';
import { MoreInfoBox } from 'components/page/advertisement/detail/components/mainContent/moreInfoBox/MoreInfoBox';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { Location } from 'components/page/advertisement/detail/components/mainContent/location/Location';
import { getResetEcommerceEvent } from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import useSession from 'modules/state/app/hook/session/useSession';
import { CategoriesEnumsRecord } from 'components/page/advertisement/detail/constants/record/parameters/category/categoriesEnumsRecord';
import { AdvertiserBox } from 'components/page/advertisement/detail/components/mainContent/advertiser/AdvertiserBox';
import { IGroup } from 'components/gallery/interfaces/group/IGroup';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';

interface IMainDetailContent {
    galleryGroup: IGroup;
    isGalleryOpen: boolean;
    isMobileScreen: boolean;
    isPlentyOfPhotos: boolean;
    advertisementSlug: string;
    locationCountySlug?: string;
    breadcrumbMain: IBreadcrumb;
    isRefererFromOtherWebPage: boolean;
    advertisement: IAdvertisementDetail;
    advertisementGtmItem: IAdvertisementGtmItem;
    openGallery: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
}

export const MainDetailContent: FC<IMainDetailContent> = ({
    openGallery,
    advertisement,
    isMobileScreen,
    breadcrumbMain,
    isPlentyOfPhotos,
    locationCountySlug,
    advertisementGtmItem,
    isRefererFromOtherWebPage,
}) => {
    const { session } = useSession();
    const isAdvertiserLoggedIn = !!session;

    const isHouseOrLand =
        advertisement.parameters.category.mainValue === EMainCategory.HOUSES ||
        advertisement.parameters.category.mainValue === EMainCategory.LANDS;

    const {
        facebookPixel: { sendEvent: sendFacebookPixelEvent },
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    useEffect(() => {
        sendFacebookPixelEvent(['track', 'ViewContent']);

        if (!advertisementGtmItem) {
            return;
        }
        gtmSendEvent(
            getResetEcommerceEvent(),
            getAdvertisementViewItemEvent(advertisementGtmItem, 0)
        );
    }, [advertisementGtmItem, gtmSendEvent, sendFacebookPixelEvent]);

    const onMapView = useCallback(() => {
        gtmSendEvent(getClickMapEvent(advertisement.id, isAdvertiserLoggedIn));
    }, [advertisement.id, gtmSendEvent, isAdvertiserLoggedIn]);

    return (
        <>
            {!isPlentyOfPhotos && (
                <Box position='relative' mb={{
                    xs: 2,
                    md: 4,
                }}>

                    <Photos
                        openGallery={openGallery}
                        flags={advertisement.flags}
                        slug={advertisement.slugTitle}
                        advertisementId={advertisement.id}
                        isPlentyOfPhotos={isPlentyOfPhotos}
                        photos={advertisement.media.photos}
                        videos={advertisement.media.videos}
                        isDevProject={!!advertisement.devProject}
                        floorPlans={advertisement.media.floorPlans}
                        advertisementGtmItem={advertisementGtmItem}
                        inspections3d={advertisement.media.inspections3d}
                    />
                </Box>
            )}

            <Container disableGutters={!isMobileScreen} sx={{ paddingBottom: 5 }}>
                <Box mb={1}>
                    <Heading
                        breadcrumb={breadcrumbMain}
                        title={advertisement.title}
                        area={advertisement.parameters.area}
                        location={advertisement.location?.name}
                        price={advertisement.parameters.price.price}
                        unitPrice={advertisement.parameters.price.unitPrice}
                        noteToPrice={advertisement.parameters.price.noteToPrice}
                        powerCostsValue={
                            advertisement.parameters.price.powerCosts?.value
                        }
                        arePowerCostsIncluded={
                            advertisement.parameters.price.powerCosts?.areIncluded
                        }
                        realEstateState={
                            advertisement.parameters.realEstateState
                        }
                        category={
                            SubCategoriesToMainEnumsRecord[
                                advertisement.parameters.category.subValue
                            ]
                        }
                        showOneLineBreadcrumb={isRefererFromOtherWebPage}
                    />
                </Box>

                <Box mb={5}>
                    <DstiPromoBanner
                        onCLick={() => {
                            gtmSendEvent(
                                getTrackEvent(
                                    'vypocitat-hypoteku',
                                    CategoriesEnumsRecord[
                                        advertisement.parameters.category
                                            .mainValue
                                    ],
                                    !!advertisement.devProject
                                )
                            );
                        }}
                    />
                    <Divider />
                </Box>

                <Box mb={5}>
                    <DetailParameters
                        parameters={advertisement.parameters}
                        advertisementId={advertisement.id}
                    />
                </Box>

                {advertisement?.devProject &&
                    advertisement?.devProject?.hasShow && (
                    <>
                        <Divider />
                        <Box pt={5} mb={5}>
                            <PartOfDevProject
                                devProject={advertisement.devProject}
                            />
                        </Box>
                    </>
                )}

                <Divider />

                <Box mb={5}>
                    <DevProjectsPromoBanner
                        locationCountySlug={locationCountySlug}
                    />
                    <Divider />
                </Box>

                <Box mb={4}>
                    <Description text={advertisement.description} />
                </Box>

                <Mobile>
                    <Box mt={1.25} mb={6.25}>
                        <Ad slotMobile={AdSlot.AD_MOBILE_TOP} />
                    </Box>
                </Mobile>

                <Box mb={4}>
                    <Divider />
                </Box>

                <Box mb={4}>
                    <MoreInfoBox
                        advertisementId={advertisement.id}
                        advertisementGtmItem={advertisementGtmItem}
                    />
                </Box>

                <Box mb={5}>
                    <Divider />
                </Box>

                {advertisement.location?.point && (
                    <Box mb={4}>
                        <Location
                            lat={advertisement.location?.point?.latitude}
                            lng={advertisement.location?.point?.longitude}
                            onMapView={onMapView}
                        />
                    </Box>
                )}

                {isHouseOrLand && (
                    <Box mb={4}>
                        <Divider />
                        <LandVerificationPromoBanner />
                        <Divider />
                    </Box>
                )}

                <AdvertiserBox
                    advertisement={advertisement}
                    lmEventNskParams={[
                        'trackEvent',
                        'Advert',
                        'Contact-down',
                        'Mail',
                    ]}
                    lmEventPltParams={[
                        'trackEvent',
                        'advert-contact-nehnutelnostisk',
                        advertisement.id,
                        'Mail',
                    ]}
                />
            </Container>
        </>
    );
};
