import { FC, useMemo } from 'react';
import {
    Badges as ThemeBadges
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/badge/Badges';
import {EBadgeType} from 'modules/theme/components/listing/cards/advertisementCard/enums/badge/EBadgeType';

interface IBadges {
    isTop: boolean;
    isPremium: boolean;
}

export const Badges: FC<IBadges> = ({isTop, isPremium}) => {

    const badgeTypes = useMemo(() => {
        const badges: EBadgeType[] = [];

        if (isTop) badges.push(EBadgeType.TOP);
        if (isPremium) badges.push(EBadgeType.PREMIUM);
        return badges;
    }, [isTop, isPremium]);

    return <ThemeBadges types={badgeTypes}/>;
};