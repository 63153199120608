import { ReactNode, useCallback, useEffect, useState } from 'react';
import useLoading from 'modules/state/app/hook/useLoading';
import { ComparisonActionType } from 'components/comparison/types/ComparisonActionType';
import { deleteFromComparison } from 'components/comparison/api/deleteFromComparison';
import { EComparisonActions } from 'components/comparison/enums/EComparisonActions';
import { addToComparison } from 'components/comparison/api/addToComparison';
import { useLoginModal } from 'components/loginModal/hooks/useLoginModal';
import useNotification from 'modules/notification/useNotification';
import { useLocalStorageItem } from 'modules/localStorage/hooks/useLocalStorageitem';
import { getIsInComparison } from 'components/comparison/api/getIsInComparison';
import {useRecoilState} from 'recoil';
import {ELoginPosition} from 'modules/gtmEvents/enums/login/ELoginPosition';
import {loginPosition} from 'modules/state/gaAnalytics/login/state';

interface IReturn {
    isInComparison: boolean;
    isLoading: boolean;
    loginModal: ReactNode;
    onAddOrDeleteFromComparison: (action: ComparisonActionType) => void;
}

export const useComparison = (
    advertisementId: string,
    isInComparisonInitial?: boolean
): IReturn => {
    const [isInComparison, setIsInComparison] = useState<boolean>( isInComparisonInitial );
    const { start: startLoading, stop: stopLoading, isLoading } = useLoading();
    const { loginModal, isLoggedIn, showLoginDialog } = useLoginModal();
    const { success: showSuccessNotification, info: showInfoNotification } = useNotification();
    const [, setLoginPosition,] = useRecoilState<ELoginPosition>(loginPosition);

    const {
        get: getStorageAdvId,
        set: setStorageAdvId,
        remove: removeStorageAdvId,
    } = useLocalStorageItem('add-to-comparison');

    const onAddToComparisonFromAnonymousUser = useCallback(
        async (storageAdvId: string): Promise<void> => {
            if (!isLoggedIn || isInComparisonInitial !== undefined || storageAdvId === null) {
                return;
            }

            try {
                startLoading();

                await addToComparison(storageAdvId);
                // set FE comparison for main photo only
                if(storageAdvId === advertisementId){
                    setIsInComparison(true);
                }
                showSuccessNotification('Inzerát pridaný do porovnania');

                stopLoading();
            } catch (error) {
                console.warn(error.message);
            } finally {
                stopLoading();
            }
        },
        [
            advertisementId,
            isLoggedIn,
            isInComparisonInitial,
            startLoading,
            stopLoading,
            showSuccessNotification,
        ]
    );

    const onAddOrDeleteFromComparison = useCallback(
        async (action: ComparisonActionType): Promise<void> => {
            if (!isLoggedIn) {
                setStorageAdvId(advertisementId);
                setLoginPosition(ELoginPosition.COMPARISON);
                return showLoginDialog();
            }

            const apiAction = action === EComparisonActions.ADD_TO_COMPARISON
                ? addToComparison
                : deleteFromComparison;

            try {
                startLoading();

                await apiAction(advertisementId);

                setIsInComparison( action === EComparisonActions.ADD_TO_COMPARISON );

                if (action === EComparisonActions.ADD_TO_COMPARISON) {
                    showSuccessNotification('Inzerát pridaný do porovnania');
                } else {
                    showInfoNotification('Inzerát odobraný z porovnania');
                }

                stopLoading();
            } catch (error) {
                console.warn(error.message);
            } finally {
                stopLoading();
            }
        },
        [isLoggedIn, setStorageAdvId, advertisementId, setLoginPosition, showLoginDialog, startLoading, stopLoading, showSuccessNotification, showInfoNotification]
    );

    const fetchIsInComparison = useCallback(async (): Promise<void> => {
        if (!isLoggedIn || isInComparisonInitial !== undefined) {
            return;
        }

        try {
            startLoading();

            if(getStorageAdvId() !== null){
                await onAddToComparisonFromAnonymousUser(getStorageAdvId());
            }

            const { data } = await getIsInComparison(advertisementId);

            setIsInComparison(data);

            stopLoading();
            removeStorageAdvId();
        } catch (error) {
            console.warn(error.message);
        } finally {
            stopLoading();
        }
    }, [
        isLoggedIn,
        isInComparisonInitial,
        startLoading,
        advertisementId,
        stopLoading,
        onAddToComparisonFromAnonymousUser,
        getStorageAdvId,
        removeStorageAdvId,
    ]);

    useEffect(() => {
        fetchIsInComparison();
    }, [fetchIsInComparison]);

    return {
        isInComparison,
        isLoading,
        loginModal,
        onAddOrDeleteFromComparison,
    };
};
