import { FC } from 'react';
import { Box } from '@mui/material';
import { Modal as DesktopModal } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/partials/desktop/Modal';
import { Modal as MobileModal } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/partials/mobile/Modal';
import { AdvertiserContactTypeEnum } from 'components/page/advertisement/detail/enums/advertiser/AdvertiserContactTypeEnum';
import { IAdvertisementGtmItem } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import { EPosition } from 'modules/gtmEvents/enums/advertisement/detail/EPosition';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

interface IContactModal {
    type: AdvertiserContactTypeEnum;
    onClose: () => void;
    advertisementId: string;
    isOpenedFromAgentContactModal?: boolean;
    advertisementGtmItem: IAdvertisementGtmItem;
    formPosition: EPosition;
    lmEventPltParams?: string[];
    lmEventNskParams?: string[];
    isPhotoGallery?: boolean;
}

export const ContactModal: FC<IContactModal> = ({
    type,
    onClose,
    advertisementId,
    isOpenedFromAgentContactModal,
    advertisementGtmItem,
    lmEventPltParams,
    lmEventNskParams,
    isPhotoGallery,
    formPosition,
}) => {

    return (
        <Box>
            <Mobile>
                <MobileModal
                    type={type}
                    onClose={onClose}
                    advertisementId={advertisementId}
                    isOpenedFromAgentContactModal={
                        isOpenedFromAgentContactModal
                    }
                    advertisementGtmItem={advertisementGtmItem}
                    lmEventPltParams={lmEventPltParams}
                    lmEventNskParams={lmEventNskParams}
                    isPhotoGallery={isPhotoGallery}
                    formPosition={formPosition}
                />

            </Mobile>
            <Desktop>
                <DesktopModal
                    type={type}
                    onClose={onClose}
                    advertisementId={advertisementId}
                    advertisementGtmItem={advertisementGtmItem}
                    lmEventPltParams={lmEventPltParams}
                    lmEventNskParams={lmEventNskParams}
                    isPhotoGallery={isPhotoGallery}
                    formPosition={formPosition}
                />
            </Desktop>
        </Box>
    );
};
