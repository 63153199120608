import { FC } from 'react';
import { Box, Divider, Grid, Theme, useMediaQuery } from '@mui/material';
import { IAdvertiser } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/advertiser/IAdvertiser';
import { AdvertiserProfile } from 'components/page/advertisement/detail/components/common/advertiser/common/advertiserProfile/AdvertiserProfile';
import { Title } from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStatic/partials/title/Title';
import { AgencyProfile } from 'components/page/advertisement/detail/components/common/advertiser/common/agencyProfile/AgencyProfile';
import { IAdvertisementGtmItem } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import { Phone } from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStatic/partials/phone/Phone';
import { AdvertiserContactForm } from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStatic/partials/form/AdvertiserContactForm';

interface IAdvertiserBox {
    advertisementId: string;
    advertiser: IAdvertiser;
    advertisementGtmItem: IAdvertisementGtmItem;
    lmEventPltParams?: string[];
    lmEventNskParams?: string[];
}

export const AdvertiserBox: FC<IAdvertiserBox> = ({
    advertiser,
    advertisementId,
    advertisementGtmItem,
    lmEventPltParams,
    lmEventNskParams,
}) => {
    const hasPhoneNumber = !!advertiser.mainPhone;

    // TODO DODO Phone refactor
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    return (
        <>
            <Box mb={2}>
                <Title />
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Box id='contact-advertiser-form'>
                        <Box mb={4}>
                            <AdvertiserProfile
                                id={advertiser.id}
                                name={advertiser.name}
                                type={advertiser.type}
                                isPro={advertiser.isPro}
                                photoUrl={advertiser.photoUrl}
                                slugName={advertiser.slugName}
                                advertisementId={advertisementId}
                            />
                        </Box>

                        {hasPhoneNumber && (
                            <Box mb={4}>
                                <Phone
                                    isMobile={isMobile}
                                    advertiserId={advertiser.id}
                                    phoneNum={advertiser.mainPhone}
                                    advertisementId={advertisementId}
                                    advertiser={advertiser}
                                    advertisementGtmItem={advertisementGtmItem}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>

                {isMobile && (
                    <Grid item xs={12}>
                        <Box mb={4}>
                            <Divider />
                        </Box>
                    </Grid>
                )}

                <Grid item xs={12} md={6}>
                    {advertiser?.agency && (
                        <Box mb={4}>
                            <AgencyProfile
                                agency={advertiser.agency}
                                branch={advertiser.branch}
                                advertisementId={advertisementId}
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>

            <AdvertiserContactForm
                advertisementId={advertisementId}
                advertisementGtmItem={advertisementGtmItem}
                lmEventPltParams={lmEventPltParams}
                lmEventNskParams={lmEventNskParams}
            />
        </>
    );
};
