import React, { FC } from 'react';
import { Box } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { Input } from 'modules/theme/components/fields/input/Input';
import { InputsMergedWrapper } from 'modules/theme/components/fields/input/partials/InputsMergedWrapper';
import { CheckboxWithLabel } from 'modules/theme/components/fields/checkbox/CheckboxWithLabel';
import { IContactAdvertiser } from 'components/page/advertisement/detail/interfaces/advertiser/IContactAdvertiser';
import { Title } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/contactForm/mobile/partials/firstStep/partials/title/Title';
import { CheckboxDisclaimer } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/common/checkboxDisclaimer/CheckboxDisclaimer';
//import { Recaptcha } from 'components/recaptcha/components/Recaptcha';

interface IFirstStep {
    values: IContactAdvertiser;
    setFieldValue: (
        field: string,
        value: string | boolean,
        shouldValidate?: boolean
    ) => void;
    setFieldTouched: (
        field: string,
        isTouched?: boolean,
        shouldValidate?: boolean
    ) => void;
    touched: FormikTouched<IContactAdvertiser>;
    errors: FormikErrors<IContactAdvertiser>;
    disabled?: boolean;
}

const SecondStep: FC<IFirstStep> = ({
    values,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    disabled,
}) => {
    return (
        <>
            <Box mb={2}>
                <Title />
            </Box>
            <Box mb={2.5}>
                <InputsMergedWrapper>
                    <Input
                        disabled={disabled}
                        value={values.name}
                        label="Meno a priezvisko *"
                        errorMsg={touched.name && errors.name}
                        onChange={(value) => setFieldValue('name', value)}
                        onBlur={() => setFieldTouched('name')}
                    />
                    <Input
                        label="Telefón *"
                        disabled={disabled}
                        value={values.phoneNumber}
                        errorMsg={touched.phoneNumber && errors.phoneNumber}
                        onChange={(value) =>
                            setFieldValue('phoneNumber', value)
                        }
                        onBlur={() => setFieldTouched('phoneNumber')}
                    />
                    <Input
                        label="E-mail *"
                        disabled={disabled}
                        value={values.mail}
                        errorMsg={touched.mail && errors.mail}
                        onChange={(value) => setFieldValue('mail', value)}
                        onBlur={() => setFieldTouched('mail')}
                    />
                </InputsMergedWrapper>
            </Box>
            <Box mb={2.5}>
                <CheckboxWithLabel
                    disabled={disabled}
                    isChecked={values.gdpr}
                    label={<CheckboxDisclaimer />}
                    errMsg={touched.gdpr && errors.gdpr}
                    onClick={(value) => setFieldValue('gdpr', value)}
                    onBlur={() => setFieldTouched('gdpr')}
                />

            </Box>
            {/*<Box mb={2.5}>*/}
            {/*    <Recaptcha*/}
            {/*        errorMsg={touched.recaptchaToken && errors.recaptchaToken}*/}
            {/*        onChange={(token) => setFieldValue('recaptchaToken', token)}*/}
            {/*    />*/}
            {/*</Box>*/}
        </>
    );
};

export default SecondStep;
