import { FC } from 'react';
import {
    TopContent
} from 'components/page/advertisement/detail/components/common/photos/partials/photoOverlay/partials/topContent/TopContent';
import {
    BottomContent
} from 'components/page/advertisement/detail/components/common/photos/partials/photoOverlay/partials/bottomContent/BottomContent';
import { IFlags } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/flags/IFlags';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';
import {IAdvertisementGtmItem} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';

interface IPhotoOverlay {
    flags: IFlags;
    areVideos?: boolean;
    photosCount?: number;
    isDevProject: boolean;
    areFloorPlans?: boolean;
    isInspection3d?: boolean;
    advertisementId: string;
    openGallery: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
    advertisementGtmItem: IAdvertisementGtmItem;
}

export const PhotoOverlay: FC<IPhotoOverlay> = ({
    flags,
    areVideos,
    photosCount,
    openGallery,
    isDevProject,
    areFloorPlans,
    isInspection3d,
    advertisementId,
    advertisementGtmItem,
}) => {

    return <>
        <TopContent
            flags={flags}
            advertisementId={advertisementId}
            advertisementGtmItem={advertisementGtmItem}
        />

        <BottomContent
            areVideos={areVideos}
            photosCount={photosCount}
            openGallery={openGallery}
            isDevProject={isDevProject}
            areFloorPlans={areFloorPlans}
            isInspection3d={isInspection3d}
            advertisementId={advertisementId}
        />
    </>;
};
