import {FC} from 'react';
import {useContactAdvertiser} from 'components/page/advertisement/detail/hooks/advertiser/useContactAdvertiser';
import useNotification from 'modules/notification/useNotification';
import {Formik, FormikProps} from 'formik';
import {
    AdvertiserContactTypeEnum
} from 'components/page/advertisement/detail/enums/advertiser/AdvertiserContactTypeEnum';
import {
    getContactAdvertiserValidationSchema
} from 'components/page/advertisement/detail/helpers/advertiser/GetContactAdvertiserValidationSchema';
import {IContactAdvertiser} from 'components/page/advertisement/detail/interfaces/advertiser/IContactAdvertiser';
import useSession from 'modules/state/app/hook/session/useSession';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import {
    getGenerateLeadEvent,
    getPurchaseEvent,
} from 'modules/gtmEvents/helpers/events/advertisement/common/advertisementEventsHelper';
import {EFormState} from 'modules/gtmEvents/enums/EFormState';
import {EFormStep} from 'modules/gtmEvents/enums/EFormStep';
import {EMethod} from 'modules/gtmEvents/enums/EMethod';
import {getResetEcommerceEvent} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import {EPosition} from 'modules/gtmEvents/enums/advertisement/detail/EPosition';
import {SimplifiedContactForm} from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/contactForm/desktop/SimplifiedContactForm';
import {useRecoilState, useResetRecoilState} from 'recoil';
import {advertiserFormTextState} from 'modules/state/page/advertisement/state';
import { useTracker } from 'modules/analytics/rtb/hooks/util/visit/useTracker';

interface ISimplifiedContentProps {
    advertisementId: string;
    onClose: () => void;
    initType: AdvertiserContactTypeEnum;
    advertisementGtmItem: IAdvertisementGtmItem;
    formPosition: EPosition;
    lmEventPltParams?: string[];
    lmEventNskParams?: string[];
    isPhotoGallery?: boolean;
}

export const SimplifiedContent: FC<ISimplifiedContentProps> = ({
    advertisementId,
    onClose,
    initType,
    advertisementGtmItem,
    lmEventPltParams,
    lmEventNskParams,
    isPhotoGallery,
    formPosition,
}) => {
    const [formText,] = useRecoilState<string>(advertiserFormTextState);
    const resetFormText = useResetRecoilState(advertiserFormTextState);

    const { onSend, isLoading } = useContactAdvertiser(advertisementId);
    const { success: showSuccessFlashMessage, error: showErrorFlashMessage } =
        useNotification();

    const {
        gtm: { sendEvent: gtmSendEvent },
        livemonitor: { sendEvent: livemonitorSendEvent },
    } = useAnalytics();

    const { session } = useSession();
    const isAdvertiserLoggedIn = !!session;

    const { advertiser, devProjectId } = advertisementGtmItem;

    const initFormValues: IContactAdvertiser = {
        text: formText,
        mail: '',
        phoneNumber: '',
        name: '',
        type: initType,
        gdpr: false,
        // TODO set empty string when using recaptcha
        recaptchaToken: 'remove me when using recaptcha',
    };

    let method = EMethod.SQL_SEND_EMAIL;
    if (initType === AdvertiserContactTypeEnum.INSPECTION) {
        method = EMethod.SQL_SCHEDULE_VIEWING;
    }

    const {
        rtbEventEmail
    } = useTracker();

    return (
        <>
            <Formik
                validateOnMount
                validateOnChange
                initialValues={initFormValues}
                validationSchema={getContactAdvertiserValidationSchema()}
                onSubmit={(values: IContactAdvertiser, { resetForm }) => {
                    onSend(values).then((isSentSuccessfully) => {
                        const gtmStep = EFormStep.STEP_2;

                        if (isSentSuccessfully) {
                            showSuccessFlashMessage(
                                'Vaša správa bola odoslaná'
                            );
                            resetForm({ values: initFormValues });
                            onClose();

                            if(lmEventPltParams){
                                livemonitorSendEvent({
                                    p: lmEventPltParams, o:{pn: 'plt-stats'}
                                });
                            }
                            if(lmEventNskParams){
                                livemonitorSendEvent({
                                    p: lmEventNskParams, o:{pn: 'nehnutelnosti'}
                                });
                            }

                            gtmSendEvent(
                                getGenerateLeadEvent(
                                    advertisementId,
                                    advertiser.id,
                                    advertiser.name,
                                    EFormState.SUCCESS,
                                    gtmStep,
                                    method,
                                    formPosition,
                                    advertiser?.agency?.name,
                                    devProjectId,
                                    isAdvertiserLoggedIn,
                                    isPhotoGallery,
                                ),
                                getResetEcommerceEvent(),
                                getPurchaseEvent(advertisementGtmItem, 0)
                            );

                            rtbEventEmail(advertisementId);

                        } else {
                            showErrorFlashMessage(
                                'Správu sa nepodarilo odoslať'
                            );

                            gtmSendEvent(
                                getGenerateLeadEvent(
                                    advertisementId,
                                    advertiser.id,
                                    advertiser.name,
                                    EFormState.ERROR,
                                    gtmStep,
                                    method,
                                    formPosition,
                                    advertiser?.agency?.name,
                                    devProjectId,
                                    isAdvertiserLoggedIn,
                                    isPhotoGallery,
                                )
                            );
                        }

                        resetFormText();
                    });
                }}
            >
                {(formikProps: FormikProps<IContactAdvertiser>) =>
                    <SimplifiedContactForm
                        {...formikProps}
                        disabled={isLoading}
                    />
                }
            </Formik>
        </>
    );
};
