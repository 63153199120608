import { AxiosResponse } from 'axios';
import { advertisementComparisonApi } from 'modules/api/client';

export const getIsInComparison = async (
    advertisementId: string
): Promise<AxiosResponse<boolean>> => {
    try {
        const response = await advertisementComparisonApi.isInComparison(
            advertisementId
        );

        if (response.status !== 200) {
            throw new Error(`Response status code was: ${response.status}`);
        }

        return response;
    } catch (error) {
        throw new Error(`Failed to add to favorite. Error: ${error.message}`);
    }
};
