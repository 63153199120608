import { FC, FunctionComponent, SVGProps } from 'react';
import { Grid } from '@mui/material';

import AreaIcon from 'modules/theme/components/icons/technical/Area.svg';
import ConditionDefaultIcon from 'modules/theme/components/icons/technical/ConditionDefault.svg';
import NumberOfFloorsIcon from 'modules/theme/components/icons/technical/NumberOfFloors.svg';
import DateIcon from 'modules/theme/components/icons/basic/Date.svg';
import ConstructionIcon from 'modules/theme/components/icons/technical/Construction.svg';
import EnergyCertificateDefaultIcon from 'modules/theme/components/icons/technical/EnergyCertificateDefault.svg';
import { Parameter } from 'components/page/advertisement/detail/components/mainContent/parameter/Parameter';

interface IParameter {
    label?: string;
    value: string;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

interface IParameters {
    areaLabel: string;
    area?: string;
    floor?: number;
    hasElevator: boolean;
    totalFloors?: number;
    construction: string;
    realEstateState?: string;
    yearOfConstruction?: number;
    energyRatingCertificate?: string;
}

export const Parameters: FC<IParameters> = ({
    areaLabel,
    area,
    construction,
    floor,
    hasElevator,
    totalFloors,
    realEstateState,
    yearOfConstruction,
    energyRatingCertificate,
}) => {
    const parameters: IParameter[] = [];

    if (area && areaLabel) {
        parameters.push({
            label: areaLabel,
            value: `${area} m²`,
            icon: AreaIcon,
        });
    }

    if (construction) {
        parameters.push({
            value: realEstateState,
            icon: ConditionDefaultIcon,
        });
    }

    if (floor) {
        let floorValue: string = floor.toString();
        if (totalFloors) {
            floorValue = `${floor}/${totalFloors}`;
        }
        if (hasElevator) {
            floorValue = `${floorValue} + výťah`;
        }
        parameters.push({
            label: 'Podlažie',
            value: floorValue,
            icon: NumberOfFloorsIcon,
        });
    }

    if (yearOfConstruction) {
        parameters.push({
            label: 'Rok výstavby',
            value: `${yearOfConstruction}`,
            icon: DateIcon,
        });
    }

    if (construction) {
        parameters.push({
            value: construction,
            icon: ConstructionIcon,
        });
    }

    if (energyRatingCertificate) {
        parameters.push({
            label: 'Energetický certifikát',
            value: energyRatingCertificate,
            icon: EnergyCertificateDefaultIcon,
        });
    }

    return (
        <Grid container spacing={{ md: 1 }}>
            {parameters.map((parameter) => (
                <Grid key={parameter.value} item xs={12} md={6}>
                    <Parameter
                        icon={parameter.icon}
                        label={parameter?.label}
                        value={parameter.value}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
