import {FC, useCallback, useMemo} from 'react';
import {Box, Stack} from '@mui/material';
import {
    ContactButton
} from 'components/page/advertisement/detail/components/common/advertiser/partials/common/contactButton/ContactButton';
import {
    AdvertiserContactTypeEnum
} from 'components/page/advertisement/detail/enums/advertiser/AdvertiserContactTypeEnum';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import useSession from 'modules/state/app/hook/session/useSession';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import {
    getAddToCartEvent,
    getGenerateLeadEvent,
    getPurchaseEvent,
} from 'modules/gtmEvents/helpers/events/advertisement/common/advertisementEventsHelper';
import {EFormState} from 'modules/gtmEvents/enums/EFormState';
import {EFormStep} from 'modules/gtmEvents/enums/EFormStep';
import {EMethod} from 'modules/gtmEvents/enums/EMethod';
import {getResetEcommerceEvent} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import {
    MobilePhoneNumberButton
} from 'components/page/advertisement/detail/components/common/advertiser/partials/common/phoneNumberButton/MobilePhoneNumberButton';
import {
    PhoneNumberButton
} from 'components/page/advertisement/detail/components/common/advertiser/partials/common/phoneNumberButton/PhoneNumberButton';
import {EPosition} from 'modules/gtmEvents/enums/advertisement/detail/EPosition';
import {
    useTracker as useRtbTracker
} from 'modules/analytics/rtb/hooks/util/visit/useTracker';

interface IButtonsBox {
    advertiserId: string;
    mobileVersion?: boolean;
    advertisementId: string;
    advertiserPhoneNumber?: string;
    isOpenedFromAgentContactModal?: boolean;
    advertisementGtmItem: IAdvertisementGtmItem;
    position: EPosition;
    isPhotoGallery?: boolean;
}

export const ButtonsBox: FC<IButtonsBox> = ({
    advertiserId,
    mobileVersion,
    advertisementId,
    advertiserPhoneNumber,
    isOpenedFromAgentContactModal,
    advertisementGtmItem,
    isPhotoGallery,
    position,
}) => {
    const { session } = useSession();
    const isAdvertiserLoggedIn = !!session;

    const {
        facebookPixel: { sendEvent: sendFacebookPixelEvent },
        gtm: { sendEvent: gtmSendEvent },
        livemonitor: { sendEvent: livemonitorSendEvent },
    } = useAnalytics();

    const {
        rtbEventMobile,
    } = useRtbTracker();

    const {
        advertiser,
        devProjectId,
    } = advertisementGtmItem;

    const phoneNumberLmEventNskParams = useMemo(() => {
        return ['trackEvent', (isPhotoGallery ? 'Advert-fotogallery' : 'Advert'), 'Contact', 'Telefon'];
    }, [isPhotoGallery]);

    const phoneNumberLmEventPltParams = useMemo(() => {
        return ['trackEvent', 'advert-contact-nehnutelnostisk', advertisementId, 'Telefon'];
    }, [advertisementId]);

    const onShowPhoneNumberModal = useCallback(() => {
        sendFacebookPixelEvent(['track', 'AddToCart']);
        gtmSendEvent(
            getGenerateLeadEvent(
                advertisementGtmItem.id,
                advertiser.id,
                advertiser.name,
                EFormState.PROGRESSED,
                EFormStep.STEP_2,
                EMethod.SQL_CLICK_TO_CALL,
                position,
                advertiser?.agency?.name,
                devProjectId,
                isAdvertiserLoggedIn,
                isPhotoGallery,
            ),
            getResetEcommerceEvent(),
            getPurchaseEvent(
                advertisementGtmItem,
                0,
            ),
        );
    }, [advertisementGtmItem, advertiser?.agency?.name, advertiser.id, advertiser.name, devProjectId, gtmSendEvent, isAdvertiserLoggedIn, isPhotoGallery, position, sendFacebookPixelEvent]);

    const onPhoneConcreteButtonClick = useCallback(() => {
        if (advertiserPhoneNumber && mobileVersion) {
            livemonitorSendEvent({
                p: phoneNumberLmEventNskParams, o: {pn: 'nehnutelnosti'}
            });
            livemonitorSendEvent({
                p: phoneNumberLmEventPltParams, o: {pn: 'plt-stats'}
            });
            rtbEventMobile(advertisementId);
        }
        gtmSendEvent(
            getGenerateLeadEvent(
                advertisementGtmItem.id,
                advertiser.id,
                advertiser.name,
                EFormState.SUCCESS,
                EFormStep.STEP_1,
                EMethod.SQL_CLICK_TO_CALL,
                position,
                advertiser?.agency?.name,
                devProjectId,
                isAdvertiserLoggedIn,
                isPhotoGallery,
            ),
            getResetEcommerceEvent(),
            getPurchaseEvent(
                advertisementGtmItem,
                0,
            )
        );
    }, [advertisementGtmItem, advertisementId, advertiser?.agency?.name, advertiser.id, advertiser.name, advertiserPhoneNumber, devProjectId, gtmSendEvent, isAdvertiserLoggedIn, isPhotoGallery, livemonitorSendEvent, mobileVersion, phoneNumberLmEventNskParams, phoneNumberLmEventPltParams, position, rtbEventMobile]);

    const onEmailButtonClick = useCallback(() => {
        sendFacebookPixelEvent(['track', 'Contact']);
        gtmSendEvent(
            getGenerateLeadEvent(
                advertisementId,
                advertiser.id,
                advertiser.name,
                EFormState.PROGRESSED,
                EFormStep.STEP_1,
                EMethod.SQL_SEND_EMAIL,
                position,
                advertiser.agency?.name,
                devProjectId,
                isAdvertiserLoggedIn,
                isPhotoGallery,
            ),
            getAddToCartEvent(
                advertisementGtmItem,
                0,
                1,
            )
        );
    }, [sendFacebookPixelEvent, gtmSendEvent, advertisementId, advertiser.id, advertiser.name, advertiser.agency?.name, position, devProjectId, isAdvertiserLoggedIn, isPhotoGallery, advertisementGtmItem]);

    const onInspectionButtonClick = useCallback(() => {
        sendFacebookPixelEvent(['track', 'Contact']);
        gtmSendEvent(
            getGenerateLeadEvent(
                advertisementId,
                advertiser.id,
                advertiser.name,
                EFormState.PROGRESSED,
                EFormStep.STEP_1,
                EMethod.SQL_SCHEDULE_VIEWING,
                position,
                advertiser.agency?.name,
                devProjectId,
                isAdvertiserLoggedIn,
                isPhotoGallery,
            ),
            getAddToCartEvent(
                advertisementGtmItem,
                0,
                1,
            )
        );
    }, [sendFacebookPixelEvent, gtmSendEvent, advertisementId, advertiser.id, advertiser.name, advertiser.agency?.name, position, devProjectId, isAdvertiserLoggedIn, isPhotoGallery, advertisementGtmItem]);

    return (
        <Stack flexDirection={'row'}>
            <Box width={'100%'}>
                {!mobileVersion && <PhoneNumberButton
                    btnText={'Telefón'}
                    advertiserId={advertiserId}
                    isOpenedFromAgentContactModal={
                        isOpenedFromAgentContactModal
                    }
                    onShowNumber={onShowPhoneNumberModal}
                    onPhoneClick={onPhoneConcreteButtonClick}
                    advertisementId={advertisementId}
                    lmEventNskParams={phoneNumberLmEventNskParams}
                    lmEventPltParams={phoneNumberLmEventPltParams}
                />}
                {mobileVersion && <MobilePhoneNumberButton
                    btnText={'Telefón'}
                    advertisementId={advertisementId}
                    phoneNumber={advertiserPhoneNumber}
                    onPhoneClick={onPhoneConcreteButtonClick}
                    lmEventNskParams={phoneNumberLmEventNskParams}
                    lmEventPltParams={phoneNumberLmEventPltParams}
                />}
            </Box>
            <Box width={'100%'} ml={1}>
                <ContactButton
                    btnText={'E-mail'}
                    advertisementId={advertisementId}
                    type={AdvertiserContactTypeEnum.MORE_INFO}
                    isOpenedFromAgentContactModal={
                        isOpenedFromAgentContactModal
                    }
                    advertisementGtmItem={advertisementGtmItem}
                    onButtonClick={onEmailButtonClick}
                    lmEventNskParams={['trackEvent', (isPhotoGallery ? 'Advert-fotogallery' : 'Advert'), 'Contact', 'Mail']}
                    lmEventPltParams={['trackEvent', 'advert-contact-nehnutelnostisk', advertisementId, 'Mail']}
                    isPhotoGallery={isPhotoGallery}
                    formPosition={position}
                />
            </Box>
            <Box width={'100%'} ml={1}>
                <ContactButton
                    btnText={'Obhliadka'}
                    advertisementId={advertisementId}
                    type={AdvertiserContactTypeEnum.INSPECTION}
                    isOpenedFromAgentContactModal={
                        isOpenedFromAgentContactModal
                    }
                    advertisementGtmItem={advertisementGtmItem}
                    onButtonClick={onInspectionButtonClick}
                    lmEventNskParams={['trackEvent', (isPhotoGallery ? 'Advert-fotogallery' : 'Advert'), 'Contact-obhliadka', 'Mail']}
                    lmEventPltParams={['trackEvent', 'advert-contact-obhliadka-nehnutelnostisk', advertisementId, 'Mail']}
                    isPhotoGallery={isPhotoGallery}
                    formPosition={position}
                />
            </Box>
        </Stack>
    );
};
