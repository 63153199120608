import {FC} from 'react';
import {Box} from '@mui/material';
import AreaIcon from 'modules/theme/components/icons/technical/Area.svg';
import ConditionDefaultIcon from 'modules/theme/components/icons/technical/ConditionDefault.svg';
import {ECategoriesLabel} from 'components/page/advertisement/detail/enums/heading/category/ECategoriesLabel';
import {
    HeadingCategoryIconRecord
} from 'components/page/advertisement/detail/constants/record/heading/category/headingCategoryIconRecord';
import {
    ParameterItem
} from 'components/page/advertisement/detail/components/mainContent/heading/partials/parameters/partials/parameters/partials/parameter/ParameterItem';

interface IParameters {
    area?: string;
    state?: string;
    category: ECategoriesLabel,
}

export const Parameters: FC<IParameters> = ({category, area, state}) => {
    return <Box
        display='flex'
        alignItems='center'
    >
        <Box
            fontSize='16px'
            overflow='hidden'
            whiteSpace='nowrap'
            textOverflow='ellipsis'
        >
            <ParameterItem text={category} icon={HeadingCategoryIconRecord[category]}/>

            {area && <ParameterItem text={`${area} m²`} icon={AreaIcon} withMargin/>}
            {state && <ParameterItem text={state} icon={ConditionDefaultIcon} withMargin/>}
        </Box>
    </Box>;

};
