import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { FormikProps, Form } from 'formik';
import { Button } from 'modules/theme/components/button/Button';
import { Input } from 'modules/theme/components/fields/input/Input';
import { Select } from 'modules/theme/components/fields/select/Select';
//import { Recaptcha } from 'components/recaptcha/components/Recaptcha';
import { IOption } from 'modules/theme/components/fields/radioBox/interfaces/IOption';
import { CheckboxWithLabel } from 'modules/theme/components/fields/checkbox/CheckboxWithLabel';
import { IContactAdvertiser } from 'components/page/advertisement/detail/interfaces/advertiser/IContactAdvertiser';
import { InputsMergedWrapper } from 'modules/theme/components/fields/input/partials/InputsMergedWrapper';
import { TextArea } from 'modules/theme/components/fields/textArea/TextArea';
import { Title } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/contactForm/desktop/partials/title/Title';
import { CheckboxDisclaimer } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/common/checkboxDisclaimer/CheckboxDisclaimer';

interface IContactForm extends FormikProps<IContactAdvertiser> {
    disabled: boolean;
    options: IOption[];
}

export const ContactForm: FC<IContactForm> = ({
    errors,
    values,
    touched,
    disabled,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    isValid,
    options,
}) => {
    return (
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container direction="column" rowGap={2}>
                <Grid item>
                    <Title />
                </Grid>

                <Grid item>
                    <Select
                        options={options}
                        disabled={disabled}
                        selectedValue={values.type}
                        placeholder={'Čo sa chcete opýtať?'}
                        errorMsg={touched.type && errors.type}
                        onChange={(value) => setFieldValue('type', value)}
                        onBlur={() => setFieldTouched('type')}
                    />
                </Grid>

                <Grid item>
                    <TextArea
                        rows={5}
                        disabled={disabled}
                        value={values.text}
                        errMsg={touched.text && errors.text}
                        onChange={(value) => setFieldValue('text', value)}
                        onBlur={() => setFieldTouched('text')}
                    />
                </Grid>

                <Grid item>
                    <InputsMergedWrapper>
                        <Input
                            disabled={disabled}
                            value={values.name}
                            label="Meno a priezvisko *"
                            errorMsg={touched.name && errors.name}
                            onChange={(value) => setFieldValue('name', value)}
                            onBlur={() => setFieldTouched('name')}
                        />
                        <Input
                            label="Telefón *"
                            disabled={disabled}
                            value={values.phoneNumber}
                            errorMsg={touched.phoneNumber && errors.phoneNumber}
                            onChange={(value) =>
                                setFieldValue('phoneNumber', value)
                            }
                            onBlur={() => setFieldTouched('phoneNumber')}
                        />
                        <Input
                            label="E-mail *"
                            disabled={disabled}
                            value={values.mail}
                            errorMsg={touched.mail && errors.mail}
                            onChange={(value) => setFieldValue('mail', value)}
                            onBlur={() => setFieldTouched('mail')}
                        />
                    </InputsMergedWrapper>
                </Grid>
                <Grid item>
                    <CheckboxWithLabel
                        disabled={disabled}
                        isChecked={values.gdpr}
                        label={<CheckboxDisclaimer />}
                        errMsg={touched.gdpr && errors.gdpr}
                        onClick={(value) => setFieldValue('gdpr', value)}
                        onBlur={() => setFieldTouched('gdpr')}
                    />

                </Grid>

                {/*<Grid item>*/}
                {/*    <Recaptcha*/}
                {/*        errorMsg={*/}
                {/*            touched.recaptchaToken && errors.recaptchaToken*/}
                {/*        }*/}
                {/*        onChange={(token) =>*/}
                {/*            setFieldValue('recaptchaToken', token)*/}
                {/*        }*/}
                {/*    />*/}
                {/*</Grid>*/}

                <Grid item>
                    <Button
                        type="submit"
                        disabled={disabled || !isValid}
                        text="Odoslať e-mail"
                    />
                </Grid>
            </Grid>
        </Form>
    );
};
