import { FC } from 'react';
import { Box } from '@mui/material';
import { Header } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/partials/desktop/partials/header/Header';
import { AdvertiserContactTypeEnum } from 'components/page/advertisement/detail/enums/advertiser/AdvertiserContactTypeEnum';
import { IAdvertisementGtmItem } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import { EPosition } from 'modules/gtmEvents/enums/advertisement/detail/EPosition';
import { SimplifiedContent } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/SimplifiedContent';

interface IModal {
    onClose: () => void;
    advertisementId: string;
    type: AdvertiserContactTypeEnum;
    advertisementGtmItem: IAdvertisementGtmItem;
    formPosition: EPosition;
    lmEventPltParams?: string[];
    lmEventNskParams?: string[];
    isPhotoGallery?: boolean;
}

export const SimplifiedModal: FC<IModal> = ({
    onClose,
    advertisementId,
    type,
    advertisementGtmItem,
    lmEventPltParams,
    lmEventNskParams,
    isPhotoGallery,
    formPosition,
}) => {
    return (
        <>
            <Box mb={2}>
                <Header onClick={onClose} />
            </Box>
            <Box>
                <SimplifiedContent
                    advertisementId={advertisementId}
                    initType={type}
                    onClose={onClose}
                    advertisementGtmItem={advertisementGtmItem}
                    lmEventPltParams={lmEventPltParams}
                    lmEventNskParams={lmEventNskParams}
                    isPhotoGallery={isPhotoGallery}
                    formPosition={formPosition}
                />
            </Box>
        </>
    );
};
