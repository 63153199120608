import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { Header } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/partials/mobile/partials/header/Header';
import { Content } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/Content';
import { AdvertiserContactTypeEnum } from 'components/page/advertisement/detail/enums/advertiser/AdvertiserContactTypeEnum';
import { IAdvertisementGtmItem } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import { EPosition } from 'modules/gtmEvents/enums/advertisement/detail/EPosition';

interface IModal {
    type: AdvertiserContactTypeEnum;
    onClose: () => void;
    advertisementId: string;
    isOpenedFromAgentContactModal?: boolean;
    advertisementGtmItem: IAdvertisementGtmItem;
    formPosition: EPosition;
    lmEventPltParams?: string[];
    lmEventNskParams?: string[];
    isPhotoGallery?: boolean;
}

export const Modal: FC<IModal> = ({
    type,
    onClose,
    advertisementId,
    isOpenedFromAgentContactModal,
    advertisementGtmItem,
    lmEventPltParams,
    lmEventNskParams,
    isPhotoGallery,
    formPosition,
}) => {
    const [step, setStep] = useState<number>(0);

    const goBack = () => {
        const newStepIndex = step - 1;
        if (newStepIndex >= 0) {
            setStep(newStepIndex);
        }
    };

    return (
        <>
            <Box mb={2}>
                <Header
                    onClick={onClose}
                    onBackClick={goBack}
                    isLastStep={step === 0}
                    isOpenedFromAgentContactModal={
                        isOpenedFromAgentContactModal
                    }
                />
            </Box>
            <Box>
                <Content
                    step={step}
                    initType={type}
                    onClose={onClose}
                    setStep={setStep}
                    advertisementId={advertisementId}
                    advertisementGtmItem={advertisementGtmItem}
                    lmEventPltParams={lmEventPltParams}
                    lmEventNskParams={lmEventNskParams}
                    isPhotoGallery={isPhotoGallery}
                    formPosition={formPosition}
                />
            </Box>
        </>
    );
};
