import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

interface IButton {
    children: ReactNode;
}

export const Button: FC<IButton> = ({children}) => {

    return <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        borderRadius={'99px'}
        width={{ xs: 32, md: 40 }}
        height={{ xs: 32, md: 40 }}
        bgcolor={
            (theme) => theme.palette.backgroundPrimaryElevated.main}
        sx={{cursor: 'pointer'}}
    >
        {children}
    </Box>;
};