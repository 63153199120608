import { FC } from 'react';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';
import { IPhoto } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/photo/IPhoto';
import { PhotoOverlay } from 'components/page/advertisement/detail/components/common/photos/partials/photoOverlay/PhotoOverlay';
import { ImageContent } from 'components/page/advertisement/detail/components/common/photos/partials/mainPhoto/partials/imageContent/ImageContent';
import { IFlags } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/flags/IFlags';
import { NoPhotos } from 'components/page/advertisement/detail/components/common/photos/partials/noPhotos/NoPhotos';
import { IAdvertisementGtmItem } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';

interface IMainPhoto {
    alt: string;
    flags: IFlags;
    photos: IPhoto[];
    isBordered: boolean;
    areVideos?: boolean;
    isDevProject: boolean;
    advertisementId: string;
    areFloorPlans?: boolean;
    isInspection3d?: boolean;
    isPlentyOfPhotos: boolean;
    openGallery: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
    advertisementGtmItem: IAdvertisementGtmItem;
}

export const MainPhoto: FC<IMainPhoto> = ({
    alt,
    flags,
    photos,
    areVideos,
    isBordered,
    openGallery,
    isDevProject,
    areFloorPlans,
    isInspection3d,
    advertisementId,
    isPlentyOfPhotos,
    advertisementGtmItem,
}) => {
    const areNoPhotos = !photos || !photos.length;
    const mainPhoto = areNoPhotos ? undefined : photos[0];

    return (
        <>
            {areNoPhotos ? (
                <NoPhotos />
            ) : (
                <ImageContent
                    alt={alt}
                    image={mainPhoto}
                    openGallery={openGallery}
                    isBordered={isBordered}
                    isPlentyOfPhotos={isPlentyOfPhotos}
                />
            )}

            <PhotoOverlay
                flags={flags}
                areVideos={areVideos}
                openGallery={openGallery}
                photosCount={photos.length}
                isDevProject={isDevProject}
                areFloorPlans={areFloorPlans}
                isInspection3d={isInspection3d}
                advertisementId={advertisementId}
                advertisementGtmItem={advertisementGtmItem}
            />
        </>
    );
};
