import { FC } from 'react';
import { Box } from '@mui/material';
import { Title } from 'components/page/advertisement/detail/components/mainContent/heading/partials/title/Title';
import { Price } from 'components/page/advertisement/detail/components/mainContent/heading/partials/price/Price';
import { ECategoriesLabel } from 'components/page/advertisement/detail/enums/heading/category/ECategoriesLabel';
import { Parameters } from 'components/page/advertisement/detail/components/mainContent/heading/partials/parameters/Parameters';
import { Breadcrumb as OneLineBreadcrumb } from 'components/page/advertisement/detail/components/mainContent/heading/partials/breadcrumb/Breadcrumb';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';

interface IHeading {
    title: string;
    price: string;
    area?: string;
    location?: string;
    unitPrice: string;
    noteToPrice?: string;
    powerCostsValue?: string;
    realEstateState?: string;
    category: ECategoriesLabel;
    breadcrumb: IBreadcrumb;
    arePowerCostsIncluded?: boolean;
    showOneLineBreadcrumb: boolean;
}

export const Heading: FC<IHeading> = ({
    title,
    area,
    price,
    category,
    location,
    unitPrice,
    breadcrumb,
    noteToPrice,
    realEstateState,
    powerCostsValue,
    arePowerCostsIncluded,
    showOneLineBreadcrumb,
}) => {
    return (
        <Box>
            {showOneLineBreadcrumb && <Box mb={{ xs: 1, md: 2 }}>
                <OneLineBreadcrumb breadcrumb={breadcrumb}/>
            </Box>
            }

            <Box mb={2}>
                <Title title={title} />
            </Box>

            <Box mb={{ xs: 3, md: 2 }}>
                <Parameters
                    category={category}
                    location={location}
                    area={area}
                    realEstateState={realEstateState}
                />
            </Box>

            <Box mb={{ xs: 3, md: 2 }}>
                <Price
                    price={price}
                    unitPrice={unitPrice}
                    noteToPrice={noteToPrice}
                    powerCostsValue={powerCostsValue}
                    arePowerCostsIncluded={arePowerCostsIncluded}
                />
            </Box>
        </Box>
    );
};
