import { FC } from 'react';
import { Box } from '@mui/material';
import { Favorite } from 'modules/theme/components/listing/favorite/Favorite';
import { Compare } from 'modules/theme/components/listing/compare/Compare';
import {
    Button
} from 'components/page/advertisement/detail/components/common/photos/partials/photoOverlay/partials/topContent/partials/buttons/partials/Button';
import { ComparisonActionType } from 'components/comparison/types/ComparisonActionType';
import { EComparisonActions } from 'components/comparison/enums/EComparisonActions';
import { FavoriteActionType } from 'components/favorite/types/FavoriteActionType';
import { EFavoriteActions } from 'components/favorite/enums/EFavoriteActions';

interface IButtons {
    isFavorite: boolean;
    isInComparison: boolean;
    isLoadingFavorite: boolean;
    isLoadingComparison: boolean;
    onAddOrDeleteFromFavorite: (action: FavoriteActionType) => void;
    onAddOrDeleteFromComparison: (actions: ComparisonActionType) => void;
}

export const Buttons: FC<IButtons> = ({
    isFavorite,
    isInComparison,
    isLoadingFavorite,
    isLoadingComparison,
    onAddOrDeleteFromFavorite,
    onAddOrDeleteFromComparison
}) => {

    const onFavoriteButtonClick = (): void => {
        const action = isFavorite
            ? EFavoriteActions.DELETE_FROM_FAVORITE
            : EFavoriteActions.ADD_TO_FAVORITE;

        onAddOrDeleteFromFavorite(action);
    };

    const onComparisonButtonClick = (): void => {
        const action = isInComparison
            ? EComparisonActions.DELETE_FROM_COMPARISON
            : EComparisonActions.ADD_TO_COMPARISON;

        onAddOrDeleteFromComparison(action);
    };

    return <Box display='flex' alignItems='center' gap={1.5}>
        <Button>
            <Favorite
                isActive={isFavorite}
                isDisabled={isLoadingFavorite}
                onClick={onFavoriteButtonClick}
            />
        </Button>

        <Button>
            <Compare
                isDisabled={isLoadingComparison}
                isActive={isInComparison}
                onClick={onComparisonButtonClick}
            />
        </Button>
    </Box>;
};