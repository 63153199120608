'use client';

import React, { FC, useEffect, useMemo } from 'react';
import dynamic from 'next/dynamic';
import {Box, Container, Grid, useMediaQuery, Theme} from '@mui/material';
import {
    Breadcrumbs as DesktopChainBreadcrumbs
} from 'components/page/advertisement/detail/components/mainContent/breadcrumbs/desktop/Breadcrumbs';
import {
    Breadcrumbs as MobileBreadcrumbs
} from 'components/page/advertisement/detail/components/mainContent/breadcrumbs/mobile/Breadcrumbs';
import {
    IAdvertisementDetail
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/IAdvertisementDetail';
import {StickyLayoutContent} from 'components/page/advertisement/detail/components/layout/StickyContent';
import {MainDetailContent} from 'components/page/advertisement/detail/components/layout/MainContent';
import {useDetailLayout} from 'components/page/advertisement/detail/hooks/layout/useDetailLayout';
import {useTopPromoNavbar} from 'components/page/advertisement/detail/hooks/layout/useTopPromoNavbar';
import {StickyBottomContent} from 'components/page/advertisement/detail/components/layout/StickyBottomContent';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import {
    mapIAdvertisementDetailToGtmItemMapper
} from 'modules/api/clients/advertisement/detail/helpers/mappers/advertisementDetail/mapIAdvertisementDetailToGtmItemMapper';
import {
    SubCategoriesEnumsRecord
} from 'components/page/advertisement/detail/constants/record/parameters/category/subCategoriesEnumsRecord';
import {TopPromoNavbar} from 'components/page/advertisement/detail/components/mainContent/topPromoNavbar/TopPromoNavbar';
import {MainPhotos} from 'components/page/advertisement/detail/components/common/photos/MainPhotos';
import {IBreadcrumbItems} from 'components/breadcrumbs/interfaces/IBreadcrumbItems';
import {Mobile, Desktop} from 'modules/theme/components/responsive';
import {IGroup} from 'components/gallery/interfaces/group/IGroup';
import { useRecoilState } from 'recoil';
import { pageViewAdvertisementDetailState } from 'modules/state/gaAnalytics/pageView/state';
import { useMainLayoutGallery } from 'components/page/advertisement/detail/hooks/gallery/useMainLayoutGallery';

const BottomSlidersContent = dynamic(() => import('./layout/BottomSlidersContent').then((component) => component.BottomSlidersContent), { ssr: false });

interface IProps {
    isGalleryOpen: boolean;
    advertisementSlug: string;
    breadcrumb: IBreadcrumbItems;
    advertisement: IAdvertisementDetail;
    isRefererFromOtherWebPage: boolean;
    galleryGroup: IGroup;
}

const Main: FC<IProps> = ({ advertisement, advertisementSlug, isGalleryOpen, breadcrumb, isRefererFromOtherWebPage, galleryGroup }) => {
    const { isPlentyOfPhotos } = useDetailLayout(
        advertisement.media.photos.length
    );

    const [, setPageViewAdvDetailState] = useRecoilState(pageViewAdvertisementDetailState);

    useEffect(() => {
        setPageViewAdvDetailState({
            mainCategoryValue: advertisement.parameters.category.mainValue,
            advertiserType: advertisement.advertiser.type,
        });
    }, [
        advertisement.parameters.category.mainValue,
        advertisement.advertiser.type,
        setPageViewAdvDetailState,
    ]);

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const { isVisible } = useTopPromoNavbar();

    const advertisementGtmItem: IAdvertisementGtmItem = useMemo(
        () => mapIAdvertisementDetailToGtmItemMapper(advertisement),
        [advertisement]
    );

    const { openGallery, gallery } = useMainLayoutGallery(
        galleryGroup,
        isGalleryOpen,
        advertisementSlug,
        advertisementGtmItem,
        advertisement,
    );

    return (
        <Container
            maxWidth={false}
            disableGutters={true}
        >
            <Desktop display='contents'>
                <Box pb={2} pt={2}>
                    <Container>
                        <DesktopChainBreadcrumbs
                            breadcrumbs={breadcrumb.parts}
                        />
                    </Container>
                </Box>
            </Desktop>

            <Box display={{xs: 'none', md: 'contents'}}>
                {isVisible &&
                    <TopPromoNavbar
                        location={advertisement.location?.name}
                        price={advertisement.parameters.price.price}
                        category={
                            SubCategoriesEnumsRecord[
                                advertisement.parameters.category.subValue
                            ]
                        }
                    />
                }
            </Box>

            {isPlentyOfPhotos &&
                <MainPhotos
                    openGallery={openGallery}
                    advertisement={advertisement}
                    isPlentyOfPhotos={isPlentyOfPhotos}
                    advertisementGtmItem={advertisementGtmItem}
                />
            }

            <Container disableGutters={isMobile}>

                <Grid
                    container
                    spacing={2}
                    id='main-detail-content'
                >
                    <Grid
                        item
                        md={8}
                        xs={12}
                        mb={{xs: 4, md: 3}}
                    >
                        <MainDetailContent
                            openGallery={openGallery}
                            isMobileScreen={isMobile}
                            isGalleryOpen={isGalleryOpen}
                            advertisement={advertisement}
                            breadcrumbMain={breadcrumb.main}
                            isPlentyOfPhotos={isPlentyOfPhotos}
                            advertisementSlug={advertisementSlug}
                            locationCountySlug={
                                advertisement.location?.countySlug
                            }
                            advertisementGtmItem={advertisementGtmItem}
                            isRefererFromOtherWebPage={isRefererFromOtherWebPage}
                            galleryGroup={galleryGroup}
                        />
                    </Grid>

                    <Box display={{xs: 'none', md: 'contents'}}>
                        <Grid
                            item
                            mt={2}
                            pl={2}
                            zIndex={4}
                            position="relative"
                            xs={4} mb={{sx: 4, md: 3}}
                        >
                            <StickyLayoutContent
                                isPlentyOfPhotos={isPlentyOfPhotos}
                                advertiser={advertisement.advertiser}
                                advertisementId={advertisement.id}
                                advertisementGtmItem={advertisementGtmItem}
                            />
                        </Grid>
                    </Box>

                </Grid>
            </Container>

            <Desktop display='contents'>
                <Container>
                    <BottomSlidersContent
                        mainBreadcrumb={breadcrumb.main}
                        advertisementId={advertisement.id}
                        countySlugName={advertisement.location?.countySlug}
                    />
                </Container>
            </Desktop>

            <Mobile display='contents'>
                <BottomSlidersContent
                    mainBreadcrumb={breadcrumb.main}
                    advertisementId={advertisement.id}
                    countySlugName={advertisement.location?.countySlug}
                />
            </Mobile>

            <Mobile display='contents'>
                <Container sx={{marginBottom: 8}}>
                    <MobileBreadcrumbs
                        breadcrumbs={breadcrumb.parts}
                    />
                </Container>
            </Mobile>

            <Box display={{xs: 'contents', md: 'none'}}>
                <StickyBottomContent
                    advertisementId={advertisement.id}
                    advertiser={advertisement.advertiser}
                    price={advertisement.parameters.price}
                    advertisementGtmItem={advertisementGtmItem}
                />
            </Box>

            { gallery }
        </Container>
    );
};

export default Main;
