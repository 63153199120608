import {FC} from 'react';
import {Theme, useMediaQuery} from '@mui/material';
import {useContactAdvertiser} from 'components/page/advertisement/detail/hooks/advertiser/useContactAdvertiser';
import useNotification from 'modules/notification/useNotification';
import {IOption} from 'modules/theme/components/fields/radioBox/interfaces/IOption';
import {Formik, FormikProps} from 'formik';
import {
    AdvertiserContactTypeEnum
} from 'components/page/advertisement/detail/enums/advertiser/AdvertiserContactTypeEnum';
import {
    getContactAdvertiserValidationSchema
} from 'components/page/advertisement/detail/helpers/advertiser/GetContactAdvertiserValidationSchema';
import {IContactAdvertiser} from 'components/page/advertisement/detail/interfaces/advertiser/IContactAdvertiser';
import {
    ContactForm as DesktopContactForm
} from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/contactForm/desktop/ContactForm';
import {
    ContactForm as MobileContactForm
} from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/contactForm/mobile/ContactForm';
import useSession from 'modules/state/app/hook/session/useSession';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import {
    getGenerateLeadEvent,
    getPurchaseEvent,
} from 'modules/gtmEvents/helpers/events/advertisement/common/advertisementEventsHelper';
import {EFormState} from 'modules/gtmEvents/enums/EFormState';
import {EFormStep} from 'modules/gtmEvents/enums/EFormStep';
import {EMethod} from 'modules/gtmEvents/enums/EMethod';
import {getResetEcommerceEvent} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import {EPosition} from 'modules/gtmEvents/enums/advertisement/detail/EPosition';
import { Desktop, Mobile } from 'modules/theme/components/responsive';
import { useTracker } from 'modules/analytics/rtb/hooks/util/visit/useTracker';

interface IContent {
    advertisementId: string;
    onClose: () => void;
    initType: AdvertiserContactTypeEnum;
    step?: number;
    setStep?: (step: number) => void;
    advertisementGtmItem: IAdvertisementGtmItem;
    formPosition: EPosition;
    lmEventPltParams?: string[];
    lmEventNskParams?: string[];
    isPhotoGallery?: boolean;
}

export const Content: FC<IContent> = ({
    advertisementId,
    onClose,
    initType,
    step,
    setStep: setMobileStep,
    advertisementGtmItem,
    lmEventPltParams,
    lmEventNskParams,
    isPhotoGallery,
    formPosition,
}) => {
    const { onSend, isLoading } = useContactAdvertiser(advertisementId);
    const { success: showSuccessFlashMessage, error: showErrorFlashMessage } =
        useNotification();

    const {
        gtm: { sendEvent: gtmSendEvent },
        livemonitor: { sendEvent: livemonitorSendEvent },
    } = useAnalytics();

    const {
        rtbEventEmail
    } = useTracker();

    const { session } = useSession();
    const isAdvertiserLoggedIn = !!session;

    // need gtmStep
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const { advertiser, devProjectId } = advertisementGtmItem;

    const options: IOption[] = [
        {
            value: AdvertiserContactTypeEnum.MORE_INFO,
            name: 'Podrobnejšie informácie o nehnuteľnosti',
        },
        {
            value: AdvertiserContactTypeEnum.INSPECTION,
            name: 'Mám záujem o obhliadku',
        },
        {
            value: AdvertiserContactTypeEnum.FLOOR_PLAN,
            name: 'Mám záujem o pôdorys nehnuteľnosti',
        },
    ];

    const initFormValues: IContactAdvertiser = {
        text: 'Dobrý deň, kontaktujte ma, prosím, ohľadom informácií o ponúkaných nehnuteľnostiach.',
        mail: '',
        phoneNumber: '',
        name: '',
        type: initType,
        gdpr: false,
        // TODO set empty string when using recaptcha
        recaptchaToken: 'remove me when using recaptcha',
    };

    let method = EMethod.SQL_SEND_EMAIL;
    if (initType === AdvertiserContactTypeEnum.INSPECTION) {
        method = EMethod.SQL_SCHEDULE_VIEWING;
    }

    return (
        <>
            <Formik
                validateOnMount
                validateOnChange
                initialValues={initFormValues}
                validationSchema={getContactAdvertiserValidationSchema()}
                onSubmit={(values: IContactAdvertiser, { resetForm }) => {
                    onSend(values).then((isSentSuccessfully) => {
                        let gtmStep = EFormStep.STEP_2;
                        if (isMobile) {
                            gtmStep = EFormStep.STEP_3;
                        }

                        if (isSentSuccessfully) {
                            showSuccessFlashMessage(
                                'Vaša správa bola odoslaná'
                            );
                            resetForm({ values: initFormValues });
                            onClose();

                            if(lmEventPltParams){
                                livemonitorSendEvent({
                                    p: lmEventPltParams, o:{pn: 'plt-stats'}
                                });
                            }
                            if(lmEventNskParams){
                                livemonitorSendEvent({
                                    p: lmEventNskParams, o:{pn: 'nehnutelnosti'}
                                });
                            }

                            gtmSendEvent(
                                getGenerateLeadEvent(
                                    advertisementId,
                                    advertiser.id,
                                    advertiser.name,
                                    EFormState.SUCCESS,
                                    gtmStep,
                                    method,
                                    formPosition,
                                    advertiser?.agency?.name,
                                    devProjectId,
                                    isAdvertiserLoggedIn,
                                    isPhotoGallery,
                                ),
                                getResetEcommerceEvent(),
                                getPurchaseEvent(advertisementGtmItem, 0)
                            );
                            rtbEventEmail(advertisementId);
                        } else {
                            showErrorFlashMessage(
                                'Správu sa nepodarilo odoslať'
                            );

                            gtmSendEvent(
                                getGenerateLeadEvent(
                                    advertisementId,
                                    advertiser.id,
                                    advertiser.name,
                                    EFormState.ERROR,
                                    gtmStep,
                                    method,
                                    formPosition,
                                    advertiser?.agency?.name,
                                    devProjectId,
                                    isAdvertiserLoggedIn,
                                    isPhotoGallery,
                                )
                            );
                        }
                    });
                }}
            >
                {(formikProps: FormikProps<IContactAdvertiser>) => (
                    <>
                        <Mobile>
                            <MobileContactForm
                                step={step}
                                {...formikProps}
                                options={options}
                                setStep={(step: number) => {
                                    if (step === 1) {
                                        gtmSendEvent(
                                            getGenerateLeadEvent(
                                                advertisementId,
                                                advertiser.id,
                                                advertiser.name,
                                                EFormState.PROGRESSED,
                                                EFormStep.STEP_2,
                                                method,
                                                formPosition,
                                                advertiser?.agency?.name,
                                                devProjectId,
                                                isAdvertiserLoggedIn,
                                                isPhotoGallery,
                                            )
                                        );

                                        rtbEventEmail(advertisementId);
                                    }
                                    setMobileStep(step);
                                }}
                                disabled={isLoading}
                            />
                        </Mobile>
                        <Desktop>
                            <DesktopContactForm
                                {...formikProps}
                                options={options}
                                disabled={isLoading}
                            />
                        </Desktop>
                    </>
                )}
            </Formik>
        </>
    );
};
