import { FC } from 'react';
import { Stack } from '@mui/material';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { Title } from 'components/page/advertisement/detail/components/mainContent/detailParameters/partials/landingParameters/partials/title/Title';
import { Parameters } from 'components/page/advertisement/detail/components/mainContent/detailParameters/partials/landingParameters/partials/parameters/Parameters';
import { AREA_LABELS } from 'components/page/advertisement/detail/constants/record/parameters/area/areaEnumsRecord';

interface ILeadingParameters {
    category: EMainCategory;
    title: string;
    area?: string;
    floor?: number;
    hasElevator: boolean;
    totalFloors?: number;
    construction: string;
    realEstateState?: string;
    yearOfConstruction?: number;
    energyRatingCertificate?: string;
}

export const LandingParameters: FC<ILeadingParameters> = ({
    category,
    title,
    area,
    floor,
    hasElevator,
    totalFloors,
    construction,
    realEstateState,
    yearOfConstruction,
    energyRatingCertificate,
}) => {
    return (
        <Stack direction="column" gap={2}>
            <Title title={title} />
            <Parameters
                areaLabel={AREA_LABELS[category]}
                area={area}
                floor={floor}
                hasElevator={hasElevator}
                totalFloors={totalFloors}
                construction={construction}
                realEstateState={realEstateState}
                yearOfConstruction={yearOfConstruction}
                energyRatingCertificate={energyRatingCertificate}
            />
        </Stack>
    );
};
